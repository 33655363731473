import React, { useEffect } from 'react';
import { useAddToWishlist } from '../api/addToWishlist';
import { Button, Spinner } from 'components/Elements';
import { Heart } from 'react-feather';
import { useGetWishlistItem } from '../api/getWishlistItem';
import { useRemoveFromWishlist } from '../api/removeFromWishlist';
import { FaHeart } from 'react-icons/fa';

export const AddToWishlist = ({ loanId, lendMoney }) => {
  const addToWishlistMutation = useAddToWishlist();

  const removeFromWishlistMutation = useRemoveFromWishlist();

  const wishlistItemQuery = useGetWishlistItem({ id: loanId });

  if (wishlistItemQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!wishlistItemQuery.data) return null;

  if (wishlistItemQuery.data?.isWishlisted) {
    return (
      <button
        onClick={async () => {
          await removeFromWishlistMutation.mutateAsync({ data: { loanId } });
        }}
        className="flex flex-row items-center space-x-2"
      >
        {addToWishlistMutation.isLoading ? <Spinner size="sm" /> : null}
        <FaHeart className={`h-6 w-6 ${lendMoney ? '' : 'text-secondary-100'} `} />
      </button>
    );
  }

  return (
    <button
      disabled={wishlistItemQuery.data?.isWishlisted}
      onClick={async () => {
        await addToWishlistMutation.mutateAsync({ data: { loanId } });
      }}
      className="flex flex-row items-center space-x-2"
    >
      {addToWishlistMutation.isLoading ? <Spinner size="sm" /> : null}
      <Heart />
    </button>
  );
};
