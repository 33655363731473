import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const removeFromWishlist = ({ data }) => {
  return axios.post(`/wishlists/remove`, data);
};

removeFromWishlist.propTypes = {
  data: PropTypes.shape({
    loanId: PropTypes.string.isRequired,
  }).isRequired,
};

export const useRemoveFromWishlist = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (removedLoanId) => {},
    onError: (_, __, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData('wishlist', context.previousItems);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('wishlist');
      addNotification({
        type: 'success',
        title: 'Removed from wishlist',
      });
    },
    ...config,
    mutationFn: removeFromWishlist,
  });
};
