import { Form } from 'components/Form';
import React from 'react';
import { useVerify } from '../api/verify';
import { Button, DialogTitle } from 'components/Elements';
import VerificationInput from 'react-verification-input';

export const Verify = ({ close }) => {
  const verifyMutation = useVerify();

  const [code, setCode] = React.useState('');

  return (
    <Form
      id="lend-now"
      onSubmit={async () => {
        await verifyMutation.mutateAsync({
          data: {
            code,
          },
        });
      }}
    >
      {() => (
        <>
          {verifyMutation.isSuccess ? (
            <>
              <div className="text-lg text-center font-semibold">Awesome!</div>
              <div className="text-center">
                You've successfully verified your phone number. You can now make loan offers.{' '}
              </div>
              <div className="flex flex-row items-center justify-center">
                <Button onClick={close}>Close</Button>
              </div>
            </>
          ) : (
            <>
              <DialogTitle className="text-xl text-center font-bold mb-4">
                Verify Phone Number
              </DialogTitle>
              <>Please enter the verification code sent to your phone number.</>
              <VerificationInput
                length={5}
                placeholder=""
                classNames={{
                  container: 'container',
                  character: 'character',
                  characterInactive: 'character--inactive',
                  characterSelected: 'character--selected',
                  characterFilled: 'character--filled',
                }}
                onChange={(value) => {
                  setCode(value);
                }}
              />
              <div className="flex flex-row items-center space-x-2">
                <Button
                  type="submit"
                  isLoading={verifyMutation.isLoading}
                  className="w-full"
                  variant="secondary"
                >
                  Verify
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Form>
  );
};
