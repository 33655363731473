import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React from 'react';
import { useLendNow } from '../api/lendNow';
import { Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { toWords } from 'utils/toWords';

export function LendNow({ id, amount, term, interest, monthlyPayment }) {
  const [show, setShow] = React.useState(false);

  const lendNowMutation = useLendNow();

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="inverseSecondary">
        Lend Now
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <Form
            id="lend-now"
            onSubmit={async () => {
              await lendNowMutation.mutateAsync({
                data: {
                  id,
                },
              });
              navigate('/app/lend-money/offer-submitted');
            }}
          >
            {({ register, formState, watch, setValue }) => (
              <>
                <DialogTitle className="text-xl text-center font-bold mb-4">Lend Now</DialogTitle>
                <DialogDescription>
                  You are accepting to lend {amount?.toLocaleString()} UGX at an interest of{' '}
                  {interest}% per month. Borrower will repay back UGX{' '}
                  {monthlyPayment?.toLocaleString()} monthly for {term} months.
                </DialogDescription>
                <div className="flex flex-row items-center space-x-2">
                  <Button
                    onClick={handleClose}
                    type="button"
                    className="w-full"
                    variant="inverseSecondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isLoading={lendNowMutation.isLoading}
                    className="w-full"
                    variant="secondary"
                  >
                    Submit Offer
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
