import Select from 'react-select';
import clsx from 'clsx';
import { FieldWrapper } from './FieldWrapper';

export const ReactSelectField = (props) => {
  const {
    label,
    options,
    error,
    setValue,
    name,
    onInputChange,
    required,
    defaultValue,
    value,
    onChange,
    onMenuClose,
    isMulti = false,
    placeholder,
    className,
  } = props;

  // Function to handle select state change
  const onSelectStateChange = (selectState) => {
    setValue(name, selectState ? selectState.value : null);
    // If onChange is provided, call it with the updated value
    if (onChange) {
      onChange(selectState);
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Select
        placeholder={placeholder}
        className={clsx(
          'mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md',
          className
        )}
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        onMenuClose={onMenuClose}
        name={name}
        required={required}
        isMulti={isMulti}
        onInputChange={onInputChange}
        // defaultValue={defaultValue}
        defaultValue={defaultValue ? options.find((opt) => opt.value === defaultValue) : null}
        options={options}
        onChange={onSelectStateChange}
        maxMenuHeight={100}
      />
    </FieldWrapper>
  );
};
