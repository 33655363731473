import { Spinner } from 'components/Elements';
import { loginWithPhoneAndPassword, getUser, registerWithPhoneAndPassword } from 'features/auth';
import storage from 'utils/storage';
import { initReactQueryAuth } from 'react-query-auth';

async function handleUserResponse(data) {
  const { token, user, company } = data;
  storage.setToken(token);
  let companyUser;
  if (company) {
    companyUser = { ...user, company };
    return companyUser;
  }
  return user;
}

async function loadUser() {
  if (storage.getToken()) {
    const { user, company } = await getUser();

    if (company) {
      const loadedUser = { ...user, company };

      return loadedUser;
    }

    return user;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithPhoneAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  const response = await registerWithPhoneAndPassword(data);
  return response;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
