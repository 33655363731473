import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getLenderProfile = () => {
  return axios.get('/lenders/profile');
};

export const useLenderProfile = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['lender_profile'],
    queryFn: () => getLenderProfile(),
  });
};
