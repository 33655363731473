import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';

export const ExecutiveSuccess = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <WebLayout>
      <Head
        title="Apply For Executive Guarantee"
        description="
        Enabling Finance Limited - Apply For Executive Guarantee
      "
      />
      <div className="py-16">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-16">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-800">Apply For Executive Guarantee</h2>
          </div>
          <div className="text-center font-bold"> Thank you for your application.</div>
          <div className="mb-6 text-center">
            Our administrators will review and reach out to you for verification. Once your account
            has been verified, you'll receive an email link to activate your account.
          </div>
          <div className="flex flex-row items-center justify-center">
            <Button onClick={() => navigate('/')}>Go Back Home</Button>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
