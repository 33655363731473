import React from 'react';
import HuraayImg from 'assets/huraay.svg';
import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';

export const OfferSubmitted = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto">
      <div className="bg-white rounded-2xl shadow p-6 mt-24 mx-12 md:mx-64">
        <img src={HuraayImg} alt="Huraay" className=" mx-auto" />
        <h1 className="text-3xl text-center mt-8">Success!</h1>
        <p className="text-center mt-4">
          Your offer has been submitted successfully. You will be notified when the borrower
          accepts.
        </p>
        <div className="flex flex-row justify-center mt-4">
          <div className="flex flex-row items-center space-x-2">
            <Button variant="inverseSecondary" onClick={() => navigate('/app')}>
              Home
            </Button>
            <Button variant="secondary" onClick={() => navigate('/app/my-portfolio')}>
              My Portfolio
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
