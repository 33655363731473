import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';

import { useApproveLenderSignature } from '../api/approveLenderSignature';

export const ConfirmLenderSignature = ({ id }) => {
  const confirmLenderSignatureMutation = useApproveLenderSignature();

  if (confirmLenderSignatureMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.LENDER]}>
      <ConfirmationDialog
        icon="info"
        title="Confirm Signature"
        body="The signature you provided during your application for lender profile. Do you confirm?"
        triggerButton={<Button size="sm">Signature Confirmation Pending</Button>}
        confirmButton={
          <Button
            isLoading={confirmLenderSignatureMutation.isLoading}
            type="button"
            className="bg-secondary-600"
            onClick={async () => await confirmLenderSignatureMutation.mutateAsync({ id: id })}
          >
            Confirm & Sign Contract
          </Button>
        }
      />
    </Authorization>
  );
};

ConfirmLenderSignature.propTypes = {
  id: PropTypes.string.isRequired,
};
