import React from 'react';
import { FieldWrapper } from './FieldWrapper';

export const LocationField = ({ 
  label, 
  value, 
  onChange, 
  caption, 
  error,
  onClick 
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <input
            type="text"
            className="flex-1 p-2 border rounded-md"
            value={value?.displayName || ''}
            readOnly
            placeholder="Click 'Select Location' to choose location on map"
          />
          <button
            type="button"
            onClick={onClick}
            className="px-4 py-2 bg-[rgb(224,153,28)] text-white rounded-md hover:bg-[rgb(200,137,25)]"
          >
            Select Location
          </button>
        </div>
        {caption && <div className="text-sm text-gray-500">{caption}</div>}
      </div>
    </FieldWrapper>
  );
};