import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const addToCalendar = ({ id }) => {
  return axios.post(`/loans/meeting-link-clicked/${id}`);
};

addToCalendar.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useAddToCalendar = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: () => {},
    ...config,
    mutationFn: addToCalendar,
  });
};
