import { useState } from 'react'; // Import useState hook
import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';
import { Eye, EyeOff } from 'react-feather';

export const InputField = (props) => {
  const {
    id,
    type,
    label,
    className,
    onChange,
    maxLength,
    minLength,
    caption,
    registration,
    placeholder,
    passwordField,
    error,
    required,
    name,
    value,
  } = props;

  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FieldWrapper label={label} error={error}>
      <div className="relative">
        <div className="my-1 text-xs text-gray-500 flex justify-start">{caption}</div>
        <input
          id={id}
          name={name}
          value={value}
          type={showPassword ? 'text' : type}
          maxLength={maxLength}
          minLength={minLength}
          className={clsx(
            'appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
            className
          )}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
        />
        {passwordField && (
          <button
            type="button"
            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
          </button>
        )}
      </div>
    </FieldWrapper>
  );
};
