import React from 'react';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';

export const ReactNumberFormatInputField2 = ({
  label,
  value,
  name,
  onChange,
  type = 'text',
  prefix,
  suffix,
  thousandSeparator = true,
  decimalSeparator = '.',
  thousandsGroupStyle = 'thousand',
  decimalScale,
  fixedDecimalScale = false,
  allowNegative = true,
  allowEmptyFormatting = false,
  allowLeadingZeros = false,
  isNumericString = false,
  displayType = 'input',
  format,
  mask = '_',
  customInput,
  onValueChange,
  isAllowed,
  renderText,
  getInputRef,
  allowedDecimalSeparators,
  error,
  className,
  ...rest
}) => {

  return (
    <div>
      {label && <label>{label}</label>}
      <NumericFormat
        value={value}
        name={name}
        onValueChange={onChange}
        className={clsx(
          'appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        type={type}
        prefix={prefix}
        suffix={suffix}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        thousandsGroupStyle={thousandsGroupStyle}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={allowNegative}
        allowemptyformatting={allowEmptyFormatting.toString()}
        allowLeadingZeros={allowLeadingZeros}
        isnumericstring={isNumericString.toString()}
        displayType={displayType}
        format={format}
        mask={mask}
        customInput={customInput}
        isAllowed={isAllowed}
        renderText={renderText}
        getInputRef={getInputRef}
        allowedDecimalSeparators={allowedDecimalSeparators}
        {...rest}
      />
      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-sm font-semibold text-red-500">
          {error.message}
        </div>
      )}
    </div>
  );
};
