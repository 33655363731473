import React, { useState } from 'react';
import { Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Info } from 'react-feather';

export function HowToUseDialog({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleProceed = () => {
    // Handle proceed action, if needed
    localStorage.setItem('showHowToUse', 'false');
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} initialFocus={null}>
      <div className="inline-block align-start bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top  sm:p-6">
        <DialogTitle className="text-xl flex flex-col items-center space-y-2 justify-center text-center font-bold mb-4">
          <Info className="h-8 w-8 text-primary-100" />
          <p className="">Welcome to Finablr</p>
        </DialogTitle>
        <>
          <div className="text-start md:w-[90vw]">
            <div className=" text-gray-800">
              <p className="text-center font-semibold mb-3">
                Uganda's first and only Tier 4 Money Lending network application.
              </p>
              <div className="bg-orange-100 p-4 md:p-8 rounded-4xl mb-3">
                <p className="font-semibold text-orange-500 mb-3 uppercase">
                  Requirements for a loan
                </p>
                <div className="border border-b-2 border-orange-200 mb-3"></div>
                <ul className="">
                  <li className="text-gray-800 mb-4 md:mb-2 flex flex-row items-center md:space-x-4">
                    <div className="hidden md:flex md:flex-row rounded-full bg-orange-100 border border-orange-200 h-6 w-6 text-orange-500 text-center items-center justify-center">
                      <span className="text-sm">1</span>
                    </div>
                    <span>
                      Clear Front and Back images of your national id or passport. Unclear images
                      may cause delay or rejection of your loan application.
                    </span>
                  </li>
                  <li className="text-gray-800 mb-4 md:mb-2 flex flex-row items-center md:space-x-4">
                    <div className="hidden md:flex md:flex-row rounded-full bg-orange-100 border border-orange-200 h-6 w-6 text-orange-500 text-center items-center justify-center">
                      <span className="text-sm">2</span>
                    </div>
                    <span>
                      A collateral asset that you will use to secure the loan. Below are accepted
                      collateral assets;
                    </span>
                  </li>
                </ul>
                <ul className="list-disc ml-12 md:ml-24 text-sm">
                  <li>Real Estate With Land Title</li>
                  <li>Vehicles & Bikes</li>
                  <li>Computers</li>
                  <li>Smart Phones</li>
                  <li>Televisions</li>
                  <li>Other Electronics</li>
                </ul>
              </div>
              <div className="bg-orange-100 p-4 md:p-8 rounded-4xl">
                <p className="font-semibold text-orange-500 mb-3 uppercase">
                  Here's a step-by-step guide on how to borrow money
                </p>
                <div className="border border-b-2 border-orange-200 mb-3"></div>
                <ul className="list-disc ml-4">
                  <li className="text-gray-800 mb-1">
                    <span className="font-bold">Step 1</span> <br />
                    <span>Add information about your collateral asset.</span>
                  </li>
                  <ul className="mb-4">
                    <li className="text-gray-800 mb-1">
                      Please make sure you have clear images of your collateral asset.{' '}
                      <a
                        className="text-orange-500 underline outline-none"
                        target="_blank"
                        href="/app/assets"
                      >
                        Go to collateral assets
                      </a>{' '}
                      and click on new asset.
                    </li>
                  </ul>
                  <li className="text-gray-800 mb-1">
                    <span className="font-bold">Step 2</span>
                    <br />
                    <span>
                      If you've successfully added collateral, you can now make a loan request by
                      clicking on{' '}
                    </span>
                    <a
                      className="text-orange-500 underline"
                      target="_blank"
                      href="/app/pigeon-loans"
                    >
                      Borrow Money
                    </a>{' '}
                    option in your dashboard to access the pigoen loans interface that will guide
                    you through the loan application process.
                  </li>
                  <ul>
                    <li className="text-gray-800 my-2">
                      The loan application process consists of 6 steps namely;
                    </li>
                    <ul className="flex flex-col space-y-2">
                      <li className="text-gray-800 mb-1">
                        <span className="font-bold">1. Payment Method</span>
                        <br />
                        <span> Finablr offers 2 payment method when making repayments namely;</span>
                        <br />{' '}
                        <span>
                          <span className="font-bold">Interest only</span> method allows the
                          borrower to pay only the interest on the principal balance for a specific
                          period of time. After this period, the borrower must start paying both
                          principal and interest.
                        </span>
                        <br />
                        <span className="font-bold">Declining balance</span> method involves
                        payments that reduce the principal balance with each installment. As the
                        principal decreases, the interest portion of each payment also decreases
                        over time.
                      </li>
                      <li className="text-gray-800 mb-1">
                        <span className="font-bold">2. Loan Details</span>
                        <br />
                        <span>
                          This step involves entering the amount you wish to borrow, purpose of the
                          loan and the duration you wish to pay back the loan. Keep in mind all
                          loans are subject to approval by Enabling Finance Limited.
                        </span>
                      </li>
                      <li className="text-gray-800 mb-1">
                        <span className="font-bold">3. Guarantor Details</span>
                        <br />
                        <span>
                          This step involves adding a guarantor who will be responsible for your
                          loan. A loan officer will reach out to the guarantor for verification.
                        </span>
                      </li>
                      <li className="text-gray-800 mb-1">
                        <span className="font-bold">4. Collateral Assets</span>
                        <br />
                        <span>
                          This step involves selecting the collateral asset from your listings you
                          wish to use to secure the loan.
                        </span>
                      </li>
                      <li className="text-gray-800 mb-1">
                        <span className="font-bold">5. Accept Terms and Conditions</span>
                        <br />
                        <span>
                          This step involves reading and accepting the terms and conditions of the
                          loan agreement. Please read the terms and conditions carefully before
                          accepting.
                        </span>
                      </li>
                      <li className="text-gray-800 mb-1">
                        <span className="font-bold">6. Signature</span>
                        <br />
                        <span>
                          Your signature is required to complete the loan application process. This
                          step involves digitally signing the loan application.
                        </span>
                      </li>
                    </ul>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </>
        <div className="flex flex-row items-center justify-center mt-4 space-x-2">
          <button onClick={handleClose} className="p-2 px-4 bg-gray-100 w-full rounded-2xl">
            Close
          </button>
          {/* If there's a specific action needed for proceeding */}
          <button
            onClick={handleProceed}
            className="p-2 px-4 w-full rounded-2xl text-white bg-primary-100"
          >
            Dont show again
          </button>
        </div>
      </div>
    </Dialog>
  );
}
