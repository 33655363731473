import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { ContractLists } from '../components/ContractLists';

export const Contracts = () => {
  const routes = [
    {
      name: 'Loans',
      path: '/app/loans',
    },
    {
      name: 'My Contracts',
      path: '/app/contracts',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="My Contracts"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Breadcrumbs routes={routes} />
      <div className="mt-8">
        <ContractLists />
      </div>
    </ContentLayout>
  );
};
