import React from 'react';
import { formatRelativeTime } from 'utils/formatRelativeTime';

const TimelineItem = ({ item }) => {
  return (
    <div className="flex items-center mb-3 border-b border-3 border-gray-100">
      <div className="w-full p-4">
        <p className="text-gray-800">{item.comment}</p>
        <div className="flex justify-between text-gray-600 text-sm mt-2">
          <div className="flex flex-row items-center space-x-2">
            <div className="rounded-full h-6 w-6 border text-center">
              {item.username?.slice(0, 1)}
            </div>
            <span className="">{item?.username}</span>
          </div>
          <span>{formatRelativeTime(item.date)}</span>
        </div>
      </div>
    </div>
  );
};

export const MyLoanLog = ({ data }) => {
  return (
    <div className="container mx-auto my-8">
      {data.map((item) => (
        <TimelineItem key={item.id} item={item} />
      ))}
    </div>
  );
};
