import { Navigate, Route, Routes } from 'react-router-dom';

import { Contract } from './Contract';
import { Contracts } from './Contracts';
import { AuditLog } from './AuditLog';

export const ContractsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Contracts />} />
      <Route path=":id" element={<Contract />} />
      <Route path=":id/audit-log" element={<AuditLog />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
