import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

export function DaysInArrearsInfo() {
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button onClick={handleOpen}>
        <FaInfoCircle className="text-gray-400 dark:text-gray-300" />
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Days In Arrears</DialogTitle>
          <DialogDescription>
            <p>
              Days in arrears refers to the number of days that a payment is overdue beyond the
              agreed upon payment date. For example, if a payment was due on a certain date and it's
              now X days past that date, then the number of days in arrears would be X.
            </p>
            <p>
              This metric is commonly used in financial contexts to assess the timeliness of
              payments. Higher numbers of days in arrears may indicate potential financial
              difficulties or inefficiencies in payment processing.
            </p>
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
