import { ToWords } from 'to-words';

export const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      name: 'Ugandan Shilling',
      plural: 'Ugandan Shillings',
      symbol: 'UGX',
      fractionalUnit: {
        name: 'Cent',
        plural: 'Cents',
        symbol: '',
      },
    },
  },
});

export function convertToWords(value) {
  // Check for undefined or invalid input
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    // Return nothing or undefined for invalid inputs
    return;
  }

  // Call the toWords.convert() method only for valid input
  return toWords.convert(value);
}
