import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';

import { useApproveBorrowerSignature } from '../api/approveBorrowerSignature';
import { MdWarningAmber } from 'react-icons/md';

export const ConfirmBorrowerSignature = ({ id }) => {
  const confirmBorrowerSignatureMutation = useApproveBorrowerSignature();

  if (confirmBorrowerSignatureMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.BORROWER]}>
      <ConfirmationDialog
        icon="info"
        title="Confirm Signature"
        body="The signature you provided during loan application process belongs to you and is valid to be used for your loan application. Do you confirm?"
        triggerButton={
          <Button
            // size="sm"
            startIcon={<MdWarningAmber className="text-white" />}
            className="uppercase"
          >
            Borrower Signature Required
          </Button>
        }
        confirmButton={
          <Button
            isLoading={confirmBorrowerSignatureMutation.isLoading}
            type="button"
            className="bg-primary-600"
            onClick={async () => await confirmBorrowerSignatureMutation.mutateAsync({ id: id })}
          >
            Confirm & Sign Contract
          </Button>
        }
      />
    </Authorization>
  );
};

ConfirmBorrowerSignature.propTypes = {
  id: PropTypes.string.isRequired,
};
