import { Navigate, Route, Routes } from 'react-router-dom';

import { LendMoney } from './LendMoney';
import { LoanRequest } from './LoanRequest';
import { CompleteLenderProfile } from './CompleteLenderProfile';
import { OfferSubmitted } from './OfferSubmitted';

export const LendMoneyRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<LendMoney />} />
      <Route path="complete-lender-profile" element={<CompleteLenderProfile />} />
      <Route path="offer-submitted" element={<OfferSubmitted />} />
      <Route path=":id" element={<LoanRequest />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
