import React from 'react';

export const ContractCollateral = ({ collateral }) => {
  return (
    <div>
      {collateral?.real_estate && (
        <>
          <div className="">Collateral: {collateral?.title}</div>
          <div className="">Land Location: {collateral?.real_estate?.land_location}</div>
          <div className="">Description: {collateral?.real_estate?.whats_on_land}</div>
          <div className="">Plot Size: {collateral?.real_estate?.size_of_land}</div>
        </>
      )}
      {collateral?.vehicles && (
        <>
          <div className="">Collateral: {collateral?.title}</div>
          <div className="">Description:</div>
          <div className="">Plot Size: 0.028 Acres</div>
          <div className="">Plot: 708</div>
          <div className="">Block: 7</div>
        </>
      )}
      {collateral?.computers && (
        <>
          <div className="">Collateral: {collateral?.title}</div>
          <div className="">Description:</div>
          <div className="">Plot Size: 0.028 Acres</div>
          <div className="">Plot: 708</div>
          <div className="">Block: 7</div>
        </>
      )}
      {collateral?.television && (
        <>
          <div className="">Collateral: {collateral?.title}</div>
          <div className="">Description:</div>
          <div className="">Plot Size: 0.028 Acres</div>
          <div className="">Plot: 708</div>
          <div className="">Block: 7</div>
        </>
      )}
      {collateral?.smart_phone && (
        <>
          <div className="">Collateral: {collateral?.title}</div>
          <div className="">Description:</div>
          <div className="">Plot Size: 0.028 Acres</div>
          <div className="">Plot: 708</div>
          <div className="">Block: 7</div>
        </>
      )}
      {collateral?.vehicles && (
        <>
          <div className="">Collateral: {collateral?.title}</div>
          <div className="">Description:</div>
          <div className="">Plot Size: 0.028 Acres</div>
          <div className="">Plot: 708</div>
          <div className="">Block: 7</div>
        </>
      )}
      {collateral?.other_electronics && (
        <>
          <div className="">
            Collateral:{' '}
            {collateral?.other_electronics?.nature_of_electronic === 'OTHERS' &&
              collateral?.other_electronics?.specify_others}
          </div>
          <div className="">Description: {collateral?.description}</div>
          <div className="">Model Type: {collateral?.other_electronics?.model_type}</div>
        </>
      )}
    </div>
  );
};
