export function getNextPayment(payments) {
  let nextPaymentObject = {};

  if (!payments) {
    nextPaymentObject = {
      status: 'NONE',
      installmentNumber: null,
      installmentAmount: null,
      principalDue: null,
      interestDue: null,
      balance: null,
      dateExpected: null,
    };

    return nextPaymentObject;
  }
  // Filter out payments with status "PENDING" and with installmentNumber, installmentAmount, and dateExpected
  const filteredPayments = payments.filter((payment) => {
    return (
      payment.status === 'PENDING' &&
      payment.installmentNumber !== null &&
      payment.installmentAmount !== null &&
      payment.dateExpected !== null
    );
  });

  // Sort the filtered payments by dateExpected
  const sortedPayments = filteredPayments.sort(
    (a, b) => new Date(a.dateExpected) - new Date(b.dateExpected)
  );

  // Find the first payment with a future date
  const currentDate = new Date();
  const nextPayment = sortedPayments.find(
    (payment) => new Date(payment.dateExpected) > currentDate
  );

  if (!nextPayment) {
    nextPaymentObject = {
      status: 'COMPLETE',
      installmentNumber: null,
      installmentAmount: null,
      principalDue: null,
      interestDue: null,
      balance: null,
      dateExpected: null,
    };

    return nextPaymentObject;
  }

  // Create the next payment object
  const nextInstallmentNumber = nextPayment.installmentNumber;
  const nextPaymentDate = new Date(nextPayment.dateExpected);

  nextPaymentObject = {
    status: 'PENDING',
    installmentNumber: nextInstallmentNumber,
    installmentAmount: nextPayment.installmentAmount,
    principalDue: nextPayment.balance,
    interestDue: nextPayment.interestDue,
    balance: nextPayment.balance - nextPayment.principalDue,
    dateExpected: nextPaymentDate.toISOString(),
  };

  return nextPaymentObject;
}
