import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import MotivationRafiki from 'assets/motivation-rafiki.svg';
import HomeMockup from 'assets/home-mockup.png';
import { Check } from 'react-feather';
import { useEffect } from 'react';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleStart = () => {
      if (user) {
        navigate('/app');
      }
    };
    handleStart();
  }, []);

  const handleStart = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <WebLayout>
      <Head title="Home" description="Enabling Finance Limited" />
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 mx-4 md:mx-24 items-center">
        <div className="md:ml-24">
          <div className="mt-12 text-4xl md:text-6xl text-secondary-100 font-black">
            Discover New Possibilities
          </div>
          <div className="text-gray-600 text-lg md:text-2xl mt-4">
            Enabling Finance now brings to you your ultimate solution; Finablr- a platform that
            pre-qualifies and links borrowers secured by collateral to registered lenders in Uganda.
          </div>
          <div className="mt-4">
            <Button onClick={handleStart} variant="dark" size="lg">
              Get Started
            </Button>
          </div>
        </div>

        <div className="">
          <img src={MotivationRafiki} alt="Motivation Rafiki" className="" />
        </div>
      </div>
      <div className="mx-4 md:mx-48 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="">
          <div className="text-4xl md:text-6xl font-black">Introducing Finablr</div>
          <div className="text-lg md:text-2xl">
            Uganda's first and only Tier 4 Money Lending network application
          </div>
          <div className="">
            <img src={HomeMockup} alt="Home Mockup" className="h-[40vh] md:h-[60vh]" />
          </div>
        </div>
        <div className="col-span-2 md:ml-24">
          <div className="bg-primary-100 p-8 rounded-2xl">
            <div className="font-bold text-2xl mb-3 text-white">Join thousands of happy users</div>
            <div className="text-lg md:text-2xl">
              We are constantly looking for ways to make our lives easier and safer, and Finablr is
              the App that nails it. Until now, people seeking professional and transparent services
              from Money Lenders have had no designated service center and more often than not have
              ended up in the hands of ‘sharks’ as we are commonly referred to.
            </div>
          </div>
        </div>
      </div>
      <div className="mx-12 md:mx-48 mb-8 mt-8">
        <div className="text-4xl md:text-6xl font-black">Features</div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 mb-8">
        <div className="border border-gray-800 flex flex-col items-center space-y-2 p-12">
          <div className="rounded-full bg-[#FCE3B8] p-4">
            <Check className="h-8 w-8 text-primary-100" />
          </div>
          <div className="text-4xl font-bold">Borrow</div>
          <div className="text-lg md:text-xl">
            The client uses the ‘Borrow’ feature to make a loan request at his/her desired interest
            rate and loan period and this request is pushed out to all lenders on the Finablr
            network.
          </div>
        </div>
        <div className="border border-gray-800 flex flex-col items-center space-y-2 p-12">
          <div className="rounded-full bg-[#FCE3B8] p-4">
            <Check className="h-8 w-8 text-primary-100" />
          </div>
          <div className="text-4xl font-bold">Lend</div>
          <div className="text-lg md:text-xl">
            Clients who are registered as lenders on the Finablr network use this feature to view
            and accept various loan requests thus initiating the disbursement process.
          </div>
        </div>
        <div className="border border-gray-800 flex flex-col items-center space-y-2 p-12">
          <div className="rounded-full bg-[#FCE3B8] p-4">
            <Check className="h-8 w-8 text-primary-100" />
          </div>
          <div className="text-4xl font-bold">Transactions</div>
          <div className="text-lg md:text-xl">
            This function allows a lender to track his/her closed (past), present (active) and
            delinquent (overdue) loans through an assortment of key information.
          </div>
        </div>
      </div>
      <div className="mx-4 md:mx-48 mb-8">
        <div className="text-4xl md:text-6xl font-black">Benefits</div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-16">
        <div className="">
          <div className="inline mb-4 bg-primary-100 p-4 text-sm md:text-3xl rounded-r-full uppercase w-fill text-white pr-4 md:pr-32">
            1. SIMPLE ORGANISED TRANSPARENT
          </div>
          <div className="text-lg md:text-xl ml-8 mt-4 md:mt-16 w-60 md:w-[60vw]">
            Because of its smart and neat design that ensures users have within ease of reach all
            information relevant for smooth running of their lending and borrowing ventures, the
            Finablr system is a perfect tool for any Money Lender or borrower out there that prefers
            to focus more on the art of the trade
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-16 flex justify-end">
        <div className="ml-auto">
          <div className=" mb-4 bg-primary-100 p-4 text-sm md:text-3xl rounded-l-full uppercase w-fill text-white pl-12">
            2. INTEREST DETERMINED BY BORROWER
          </div>
          <div className="text-lg md:text-xl ml-8 mt-16 w-60  md:w-[60vw]">
            Unlike any other credit system in the market, with Finablr, interest negotiations are
            initiated by the borrower. You have the opportunity to let the lenders know what your
            favourable terms are
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-16">
        <div className="">
          <div className="inline mb-4 bg-primary-100 p-4 text-sm md:text-3xl rounded-r-full uppercase w-fill text-white pr-12 md:pr-60">
            3. ANYONE's INCOME SOURCE
          </div>
          <div className="text-lg md:text-xl ml-8 mt-16 w-60 md:w-[60vw]">
            Through Finablr, we create an alternative investment vehicle not only for registered
            money lenders but also private investors with ambitions for registering as Money
            lenders. Everyone is a winner with Finablr
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-16 flex justify-end">
        <div className="ml-auto">
          <div className="mb-4 bg-primary-100 p-4 text-sm md:text-3xl rounded-l-full uppercase w-fill text-white pl-12">
            4. COLLATERAL SAFEGUARDED BY EFL
          </div>
          <div className="text-lg md:text-xl ml-8 mt-16 w-60 w-[60vw]">
            Because we know that safety of borrower collateral has always been an issue of
            contention in the Money Lending environment, with Finablr, this has been taken care of
            as collaterals are left in  EFL custody on behalf of the lender and borrower.
          </div>
        </div>
      </div>
      {/* <div className="mx-12 md:mx-48 mb-8">
        <div className="text-4xl md:text-6xl font-black mb-8">Join Our Newsletter</div>
        <Form
          id="join-newsletter"
          onSubmit={async (values) => {
            console.log(values);
          }}
          className="mt-3"
        >
          {({ register, formState }) => (
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
              <input
                label="Email"
                {...register('email', { required: 'This field is required' })}
                error={formState.errors.email && formState.errors.email.message}
                placeholder="Enter your email"
                className="w-full border border-gray-500 rounded-full p-3"
              />
              <Button isLoading={formState.isSubmitting} type="submit">
                Subscribe
              </Button>
            </div>
          )}
        </Form>
      </div> */}
    </WebLayout>
  );
};
