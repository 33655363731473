import { Button, Dialog, DialogDescription, DialogTitle, Spinner } from 'components/Elements';
import React, { useState } from 'react';
import { useLendNow } from '../api/lendNow';
import { Form, InputField, SliderField } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { calculateLoanTerm } from 'utils/calculateLoanTerm';
import { useCounterOffer } from '../api/counterOffer';
import { generatePaymentSchedule } from 'utils/generatePaymentSchedule';
import { PaymentSchedule, useGeneratePaymentSchedule } from 'features/pigeonLoans';

export function CounterOffer({
  id,
  loanAmount,
  loanTerm,
  paymentMethod,
  loanInterestRate,
  loanMonthlyPayment,
  loanProduct,
}) {
  const [show, setShow] = useState(false);

  const lendNowMutation = useCounterOffer();

  const [interestRate, setInterestRate] = useState(loanInterestRate);

  const [term, setTerm] = useState(loanTerm);
  const [amount, setAmount] = useState(loanAmount);
  const [maxMonthlyPayment, setMaxMonthlyPayment] = useState(loanMonthlyPayment);

  const [monthlyPayment, setMonthlyPayment] = useState(loanMonthlyPayment);

  const generatePaymentScheduleMutation = useGeneratePaymentSchedule();

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleMonthlyPaymentChange = (event) => {
    const enteredValue = event.target.value;
    const floatValue = parseFloat(enteredValue);

    if (isNaN(floatValue)) {
      // Handle case when input value is not a valid number or empty
      setMonthlyPayment('');
    } else {
      // Set the monthly payment to the parsed float value
      setMonthlyPayment(floatValue);
    }
  };

  const handleAmountChange = (event) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, '');
    let amount = parseInt(inputValue);
    setAmount(amount);

    // Recalculate max monthly payment
    setMaxMonthlyPayment((parseInt(inputValue) * parseFloat(interestRate)) / 100);
  };

  const handleInterestRateChange = (event) => {
    let floatValue = parseFloat(event.target.value);
    setInterestRate(floatValue);

    // Recalculate max monthly payment
    setMaxMonthlyPayment((parseInt(amount) * floatValue) / 100);
  };

  const handleTermChange = (event) => {
    let floatValue = parseFloat(event.target.value);
    setTerm(floatValue);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="secondary">
        Counter Offer
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <Form
            id="lend-now"
            onSubmit={async (values) => {
              await lendNowMutation.mutateAsync({
                data: {
                  id,
                  values: {
                    amount: amount,
                    term: term,
                    interestRate: interestRate,
                    monthlyPayment: monthlyPayment,
                  },
                },
              });
              navigate('/app/lend-money/offer-submitted');
            }}
          >
            {({ register, formState }) => (
              <>
                <DialogTitle className="text-xl text-center font-bold mb-4">
                  Make A Counter-Offer
                </DialogTitle>
                {generatePaymentScheduleMutation.isLoading ? (
                  <div className="flex flex-row items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="">
                      <div className="flex flex-row items-center space-x-4 mt-3">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                          How much can you give? (UGX)
                        </div>
                      </div>
                      <input
                        type="text"
                        value={amount}
                        className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={handleAmountChange}
                      />
                    </div>

                    <div className="">
                      <div className="flex flex-row items-center space-x-4 mt-3">
                        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                          At what interest? (%)
                        </div>
                      </div>
                      <input
                        type="number"
                        value={interestRate}
                        className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={handleInterestRateChange}
                      />
                    </div>
                    {paymentMethod === 'INTEREST_ONLY' ? (
                      <>
                        <div className="">
                          <div className="flex flex-row items-center space-x-4 mt-3">
                            <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                              How long should the loan be? (months)
                            </div>
                          </div>
                          <input
                            type="number"
                            value={term}
                            className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={handleTermChange}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {!isNaN(maxMonthlyPayment) && (
                          <>
                            <div className="">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                                How much should borrower pay back monthly?
                              </div>
                              <div className="p-1 rounded-md text-xs font-bold text-orange-600 text-start mb-2">
                                With {interestRate}% interest, you need to pay more than UGX{' '}
                                {maxMonthlyPayment.toLocaleString()} monthly.
                              </div>
                              <input
                                type="text"
                                value={monthlyPayment}
                                className="mb-3 appearance-none block w-full px-3 py-3 border dark:bg-gray-700 dark:border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                onChange={handleMonthlyPaymentChange}
                                placeholder={
                                  !isNaN(maxMonthlyPayment) && `More than UGX ${maxMonthlyPayment}`
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {interestRate ? (
                      <PaymentSchedule
                        amount={amount}
                        interestRate={interestRate}
                        period={term}
                        monthlyPayment={monthlyPayment}
                        paymentMethod={paymentMethod}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <div className="flex flex-row items-center space-x-2">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                  >
                    Cancel
                  </button>
                  <Button
                    isLoading={lendNowMutation.isLoading}
                    type="submit"
                    className="mt-4 p-2 px-4 w-full rounded-2xl text-white bg-secondary-100"
                  >
                    Submit Offer
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
