import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import PhoneInput from 'react-phone-input-2';
import { Button, Spinner } from 'components/Elements';
import { Form, InputField, LocationField } from 'components/Form';
import { useAuth } from 'lib/auth';
import 'react-phone-input-2/lib/style.css';
import { useApplyForCompanyAccount } from '../api/registerCompany';

const schema = z.object({
  firstName: z.string({
    required_error: 'First name is required',
    invalid_type_error: 'First name must be a string',
  }),
  lastName: z.string({
    required_error: 'Last name is required',
    invalid_type_error: 'Last name must be a string',
  }),
  phoneNumber: z.string({
    required_error: 'Phone Number is required',
    invalid_type_error: 'Phone Number must be a string',
  }),
  email: z
    .string({
      required_error: 'Email is required',
      invalid_type_error: 'Email must be a string',
    })
    .email({ message: 'Invalid email address' }),
  nin: z.string({
    required_error: 'National identification number is required',
    invalid_type_error: 'National identification number must be a string',
  }),
  workPlace: z.string({
    required_error: 'Your Work Place is required',
    invalid_type_error: 'Your Work Place must be a string',
  }),
  password: z.string({
    required_error: 'Password is required',
    invalid_type_error: 'Password must be a string',
  }),
});

const companySchema = z.object({
  name: z.string({
    required_error: 'Company name is required',
    invalid_type_error: 'Company name must be a string',
  }),
  location: z.string({
    required_error: 'Company location is required',
    invalid_type_error: 'Company location must be a string',
  }),
  phoneNumber: z.string({
    required_error: 'Phone Number is required',
    invalid_type_error: 'Phone Number must be a string',
  }),
  email_address: z
    .string({
      required_error: 'Email is required',
      invalid_type_error: 'Email must be a string',
    })
    .email({ message: 'Invalid email address' }),
  website: z
    .string({
      required_error: 'Website link is required',
      invalid_type_error: 'Website link must be a string',
    })
    .url({ message: 'Invalid Website Link, example : "https://example.com"' }),
  date_of_inception: z.string({
    required_error: 'Date of inception is required',
    invalid_type_error: 'Date of inception must be a string',
  }),
  incoperation_number: z.number({
    required_error: 'Incoperation number is required',
    invalid_type_error: 'Incoperation number must be a number',
  }),
  tin: z.number({
    required_error: 'TIN is required',
    invalid_type_error: 'TIN must be a number',
  }),
  representativeFirstName: z.string({
    required_error: 'Representative first name is required',
    invalid_type_error: 'Representative first name must be a string',
  }),
  representativeLastName: z.string({
    required_error: 'Representative last name is required',
    invalid_type_error: 'Representative last name must be a string',
  }),
  representativePhoneNumber: z.string({
    required_error: 'Representative Phone Number is required',
    invalid_type_error: 'Representative Phone Number must be a string',
  }),
  representativeEmailAddress: z
    .string({
      required_error: 'Representative Email is required',
      invalid_type_error: 'Representative Email must be a string',
    })
    .email({ message: 'Invalid email address' }),
  representativeNIN: z.string({
    required_error: 'Representative NIN is required',
    invalid_type_error: 'Representative NIN must be a string',
  }),
});

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();

  const [repNumber, setRepNumber] = useState('');
  const [active, setActive] = useState('PERSONAL');
  // personal input fields
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [otherName, setOtherName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [nin, setNin] = useState();
  const [workPlace, setWorkPlace] = useState();
  const [password, setPassword] = useState();
  const [loginErr, setLoginErr] = useState('');
  const [loading, setLoading] = useState(false);
  // company input fields
  const [name, setName] = useState();
  const [location, setLocation] = useState();
  const [website, setWebsite] = useState();
  const [date_of_inception, setDate_of_inception] = useState();
  const [incoperation_number, setIncoperation_number] = useState();
  const [tin, setTin] = useState();
  const [representativePhoneNumber, setRepresentativePhoneNumber] = useState();
  const [representativeEmailAddress, setRepresentativeEmailAddress] = useState();
  const [representativeNIN, setRepresentativeNIN] = useState();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      // localStorage.clear()
      const aw = localStorage.getItem('activeWindow');
      if (aw) {
        setActive(aw);
      }
      let activeWindow = aw || 'PERSONAL';
      if (activeWindow === 'PERSONAL') {
        let sv = localStorage.getItem('individualRegister');

        if (sv) {
          sv = JSON.parse(sv);
          // console.log('individualRegister', sv)
          if (sv.firstName) {
            setFirstName(sv.firstName);
            //         try {
            //           const emailSchema = schema.pick({ firstName: true });
            // const result = emailSchema.safeParse({
            //   firstName: sv.firstName
            // });
            // if (result.success) {
            //   console.log('firstName schemaData', result.data)
            // } else if (result.error) {
            //   console.log('firstName fail', result.error.message)
            // }
            //         } catch (err) {
            //           const formattedErrors = {};
            //           err.errors.forEach((error) => {
            //             formattedErrors[error.path[0]] = error.message;
            //           });
            //           console.log('firstName err', formattedErrors)
            //           // setLoading(false)
            //         }
          }
          if (sv.lastName) {
            setLastName(sv.lastName);
          }
          if (sv.phoneNumber) {
            setPhoneNumber(sv.phoneNumber);
          }
          if (sv.email) {
            setEmail(sv.email);
          }
          if (sv.nin) {
            setNin(sv.nin);
          }
          if (sv.workPlace) {
            setWorkPlace(sv.workPlace);
          }

          try {
            const schemaData = schema.safeParse({
              firstName: sv.firstName,
              lastName: sv.lastName,
              otherName: sv.otherName,
              phoneNumber: sv.phoneNumber,
              email: sv.email,
              nin: sv.nin,
              workPlace: sv.workPlace,
              password: sv.password,
            });
            if (schemaData.success) {
              console.log('schemaData', schemaData.data);
            } else if (schemaData.error) {
              console.log('schemaData fail', schemaData.error.message);
            }
            setLoading(false);
          } catch (err) {
            const formattedErrors = {};
            err.errors.forEach((error) => {
              formattedErrors[error.path[0]] = error.message;
            });
            console.log(' person err', formattedErrors);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        let sv = localStorage.getItem('companyRegister');
        console.log('companyRegister', sv);
        if (sv) {
          sv = JSON.parse(sv);
          // console.log('individualRegister', sv)
          if (sv.name) {
            setName(sv.name);
          }
          if (sv.location) {
            setLocation(sv.location);
          }
          if (sv.phoneNumber) {
            setPhoneNumber(sv.phoneNumber);
          }
          if (sv.email_address) {
            setEmail(sv.email_address);
          }
          if (sv.website) {
            setWebsite(sv.website);
          }
          if (sv.date_of_inception) {
            setDate_of_inception(sv.date_of_inception);
          }
          if (sv.incoperation_number) {
            setIncoperation_number(sv.incoperation_number);
          }
          if (sv.tin) {
            setTin(sv.tin);
          }
          if (sv.representativeFirstName) {
            setFirstName(sv.representativeFirstName);
          }
          if (sv.representativeLastName) {
            setLastName(sv.representativeLastName);
          }
          if (sv.representativePhoneNumber) {
            setRepresentativePhoneNumber(sv.representativePhoneNumber);
          }
          if (sv.representativeEmailAddress) {
            setRepresentativeEmailAddress(sv.representativeEmailAddress);
          }
          if (sv.representativeNIN) {
            setRepresentativeNIN(sv.representativeNIN);
          }

          try {
            const schemaData = companySchema.safeParse({
              name: sv.name,
              location: sv.location,
              phoneNumber: sv.phoneNumber,
              email_address: sv.email_address,
              website: sv.website,
              date_of_inception: sv.date_of_inception,
              incoperation_number: sv.incoperation_number,
              tin: sv.tin,
              representativeFirstName: sv.representativeFirstName,
              representativeLastName: sv.representativeLastName,
              representativePhoneNumber: sv.representativePhoneNumber,
              representativeEmailAddress: sv.representativeEmailAddress,
              representativeNIN: sv.representativeNIN,
            });
            if (schemaData.success) {
              console.log('schemaData', schemaData.data);
            } else if (schemaData.error) {
              console.log('schemaData fail', schemaData.error.message);
            }
            setLoading(false);
          } catch (err) {
            const formattedErrors = {};
            err.errors.forEach((error) => {
              formattedErrors[error.path[0]] = error.message;
            });
            console.log(' person err', formattedErrors);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    };
    getData();
  }, []);

  const registerCompanyMutation = useApplyForCompanyAccount();

  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  return (
    <div>
      <div className="text-sm text-center my-2">
        Get access to your free Finablr account, apply for a loan within 5 minutes!
      </div>
      <div className="bg-gray-100 shadow-md flex flex-row space-x-4 items-center justify-between rounded-md p-2 mb-3 py-3">
        <button
          className={`${
            active == 'PERSONAL' ? `bg-gray-800 text-white` : `text-gray-800 hover:bg-gray-300`
          } rounded-md p-2 px-4 text-sm`}
          onClick={() => {
            setActive('PERSONAL');
            localStorage.setItem('activeWindow', 'PERSONAL');
          }}
        >
          Personal Account
        </button>
        <button
          onClick={() => {
            setActive('COMPANY');
            localStorage.setItem('activeWindow', 'COMPANY');
          }}
          className={`${
            active == 'COMPANY' ? `bg-gray-800 text-white` : `text-gray-800 hover:bg-gray-300`
          }  rounded-md p-2 px-4 text-sm `}
        >
          Company Account
        </button>
      </div>
      {active == 'PERSONAL' && (
        <Form
          onSubmit={async (values) => {
            // values.termsAndConditionsAccepted = true;
            // values.privacyPolicyAccepted = true;
            // values.phoneNumber = phoneNumber;
            const data = {
              termsAndConditionsAccepted: true,
              privacyPolicyAccepted: true,
              firstName,
              lastName,
              otherName,
              phoneNumber,
              email,
              nin,
              password,
            };
            // console.log('register data', data)
            await register(data)
              .then((data) => {
                // console.log('login success', data)
                onSuccess();
              })
              .catch((err) => {
                // console.log('login error', err);
                setLoginErr(`${err.message}. Try again.`);
              });
          }}
          options={{
            defaultValues: {
              firstName: firstName,
              lastName: lastName,
              otherName: otherName,
              phoneNumber: phoneNumber,
              email: email,
              nin: nin,
              workPlace: workPlace,
              password: '',
            },
          }}
          schema={schema}
        >
          {({ register, formState, setValue }) => (
            <>
              <InputField
                type="text"
                label="First Name"
                name="firstName"
                value={firstName}
                error={formState.errors['firstName']}
                registration={register('firstName')}
                onChange={(event) => {
                  formState.errors['firstName'] = '';
                  setValue('firstName', event.target.value);
                  const newValues = {
                    firstName: event.target.value,
                  };
                  setFirstName(event.target.value);
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Last Name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
                name="lastName"
                value={lastName}
                onChange={(event) => {
                  formState.errors['lastName'] = '';
                  setValue('lastName', event.target.value);
                  const newValues = {
                    lastName: event.target.value,
                  };
                  setLastName(event.target.value);
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Other Name"
                error={formState.errors['otherName']}
                registration={register('otherName')}
                name="otherName"
                value={otherName}
                onChange={(event) => {
                  formState.errors['otherName'] = '';
                  setValue('otherName', event.target.value);
                  const newValues = {
                    otherName: event.target.value,
                  };
                  setOtherName(event.target.value);
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <div className="mb-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                  Phone Number
                </div>
                <PhoneInput
                  country={'ug'}
                  countryCodeEditable={false}
                  masks={{ ug: '... ... ...' }}
                  name="phoneNumber"
                  value={phoneNumber}
                  inputStyle={{
                    width: '100%',
                    height: '60px',
                    fontSize: '1rem',
                    border: '1px solid #e5e5e5',
                    borderRadius: '8px',
                  }}
                  containerClass="mt-2"
                  onChange={(phone) => {
                    formState.errors['phoneNumber'] = '';
                    setValue('phoneNumber', `+${phone}`);
                    setPhoneNumber(`+${phone}`);
                    const newValues = {
                      phoneNumber: `+${phone}`,
                    };
                    const existingValues =
                      JSON.parse(localStorage.getItem('individualRegister')) || {};
                    // Merge existing and new values
                    const mergedValues = {
                      ...existingValues,
                      ...newValues,
                    };
                    // Save merged values back to storage
                    localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                  }}
                />
                {formState.errors['phoneNumber']?.message && (
                  <div
                    role="alert"
                    aria-label={formState.errors['phoneNumber']?.message}
                    className="text-sm font-semibold text-red-500"
                  >
                    {formState.errors['phoneNumber']?.message}
                  </div>
                )}
              </div>

              <InputField
                type="email"
                label="Email Address"
                error={formState.errors['email']}
                registration={register('email')}
                name="email"
                value={email}
                onChange={(event) => {
                  formState.errors['email'] = '';
                  setValue('email', event.target.value);
                  const newValues = {
                    email: event.target.value,
                  };
                  setEmail(event.target.value);
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Identification Number"
                error={formState.errors['nin']}
                registration={register('nin')}
                caption="Provide either National ID or Passport Number"
                maxLength={14}
                name="nin"
                value={nin}
                onChange={(event) => {
                  formState.errors['nin'] = '';
                  setValue('nin', event.target.value);
                  const newValues = {
                    nin: event.target.value,
                  };
                  setNin(event.target.value);
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                label="Work Place"
                error={formState.errors['workPlace']}
                registration={register('workPlace')}
                name="workPlace"
                value={workPlace}
                onChange={(event) => {
                  formState.errors['workPlace'] = '';
                  setValue('workPlace', event.target.value);
                  const newValues = {
                    workPlace: event.target.value,
                  };
                  setWorkPlace(event.target.value);
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="password"
                label="Password"
                passwordField={true}
                error={formState.errors['password']}
                registration={register('password')}
                name="password"
                value={password}
                onChange={(event) => {
                  formState.errors['password'] = '';
                  setValue('password', event.target.value);
                  setPassword(event.target.value);
                }}
              />
              {loginErr && (
                <div
                  role="alert"
                  aria-label={loginErr}
                  className="text-sm font-semibold text-red-500"
                >
                  {loginErr}
                </div>
              )}
              <div className="">
                By signing up, you are agreeing to the{' '}
                <Link to="/general-policy" className="text-primary-100">
                  General Policy for Lenders and Borrowers
                </Link>
                . This site uses essential cookies. See our Cookie Notice for more information.
              </div>

              <div>
                <Button isLoading={isRegistering} type="submit" className="w-full">
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      {active == 'COMPANY' && (
        <Form
          onSubmit={async (values) => {
            // values.termsAndConditionsAccepted = true;
            // values.privacyPolicyAccepted = true;
            // values.creditCheckConsent = true;
            // values.phone_number = phoneNumber;
            // values.representativePhoneNumber = repNumber;
            // console.log('data', values)
            const data = {
              termsAndConditionsAccepted: true,
              privacyPolicyAccepted: true,
              creditCheckConsent: true,
              name,
              location,
              phoneNumber,
              email_address: email,
              website,
              date_of_inception,
              incoperation_number,
              tin,
              representativeFirstName: firstName,
              representativeLastName: lastName,
              representativePhoneNumber,
              representativeEmailAddress,
              representativeNIN,
            };
            await registerCompanyMutation
              .mutateAsync({ data: data })
              .then((data) => {
                // console.log('register success', data)
                navigate('/auth/company-request-submitted');
              })
              .catch((err) => {
                // console.log('login error', err);
                setLoginErr(`${err.message}. Try again.`);
              });
          }}
          options={{
            defaultValues: {
              name: name,
              location: location,
              phoneNumber: phoneNumber,
              email_address: email,
              website: website,
              date_of_inception: date_of_inception,
              incoperation_number: incoperation_number,
              tin: tin,
              representativeFirstName: firstName,
              representativeLastName: lastName,
              representativePhoneNumber: representativePhoneNumber,
              representativeEmailAddress: representativeEmailAddress,
              representativeNIN: representativeNIN,
            },
          }}
          schema={companySchema}
        >
          {({ register, formState, setValue }) => (
            <>
              <InputField
                type="text"
                label="Company Name"
                error={formState.errors['name']}
                registration={register('name')}
                name="name"
                value={name}
                onChange={(event) => {
                  formState.errors['name'] = '';
                  setValue('name', event.target.value);
                  const newValues = {
                    name: event.target.value,
                  };
                  setName(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                label="Location"
                error={formState.errors['location']}
                registration={register('location')}
                name="location"
                value={location}
                onChange={(event) => {
                  formState.errors['location'] = '';
                  setValue('location', event.target.value);
                  const newValues = {
                    location: event.target.value,
                  };
                  setLocation(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <div className="mb-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                  Company Number
                </div>
                <PhoneInput
                  country={'ug'}
                  countryCodeEditable={false}
                  masks={{ ug: '... ... ...' }}
                  name="phoneNumber"
                  value={phoneNumber}
                  inputStyle={{
                    width: '100%',
                    height: '60px',
                    fontSize: '1rem',
                    border: '1px solid #e5e5e5',
                    borderRadius: '8px',
                  }}
                  containerClass="mt-2"
                  onChange={(phone) => {
                    formState.errors['phoneNumber'] = '';
                    setValue('phoneNumber', `+${phone}`);
                    setPhoneNumber(`+${phone}`);
                    const newValues = {
                      phoneNumber: `+${phone}`,
                    };
                    const existingValues =
                      JSON.parse(localStorage.getItem('companyRegister')) || {};
                    // Merge existing and new values
                    const mergedValues = {
                      ...existingValues,
                      ...newValues,
                    };
                    // Save merged values back to storage
                    localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                  }}
                />
                {formState.errors['phoneNumber']?.message && (
                  <div
                    role="alert"
                    aria-label={formState.errors['phoneNumber']?.message}
                    className="text-sm font-semibold text-red-500"
                  >
                    {formState.errors['phoneNumber']?.message}
                  </div>
                )}
              </div>
              <InputField
                type="email"
                label="Email Address"
                error={formState.errors['email_address']}
                registration={register('email_address')}
                name="email_address"
                value={email}
                onChange={(event) => {
                  formState.errors['email_address'] = '';
                  setValue('email_address', event.target.value);
                  const newValues = {
                    email_address: event.target.value,
                  };
                  setEmail(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />

              <InputField
                type="text"
                label="Website"
                error={formState.errors['website']}
                registration={register('website')}
                name="website"
                value={website}
                onChange={(event) => {
                  formState.errors['website'] = '';
                  setValue('website', event.target.value);
                  const newValues = {
                    website: event.target.value,
                  };
                  setWebsite(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="date"
                label="Date of Inception"
                error={formState.errors['date_of_inception']}
                registration={register('date_of_inception')}
                name="date_of_inception"
                value={date_of_inception}
                onChange={(event) => {
                  formState.errors['date_of_inception'] = '';
                  setValue('date_of_inception', event.target.value);
                  const newValues = {
                    date_of_inception: event.target.value,
                  };
                  setDate_of_inception(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="number"
                label="Incoperation Number"
                error={formState.errors['incoperation_number']}
                registration={register('incoperation_number')}
                name="incoperation_number"
                value={incoperation_number}
                onChange={(event) => {
                  formState.errors['incoperation_number'] = '';
                  setValue('incoperation_number', parseInt(event.target.value));
                  const newValues = {
                    incoperation_number: parseInt(event.target.value),
                  };
                  setIncoperation_number(parseInt(event.target.value));
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="number"
                label="TIN"
                error={formState.errors['tin']}
                registration={register('tin')}
                name="tin"
                value={tin}
                onChange={(event) => {
                  formState.errors['tin'] = '';
                  setValue('tin', parseInt(event.target.value));
                  const newValues = {
                    tin: parseInt(event.target.value),
                  };
                  setTin(parseInt(event.target.value));
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Representative First Name"
                error={formState.errors['representativeFirstName']}
                registration={register('representativeFirstName')}
                name="representativeFirstName"
                value={firstName}
                onChange={(event) => {
                  formState.errors['representativeFirstName'] = '';
                  setValue('representativeFirstName', event.target.value);
                  const newValues = {
                    representativeFirstName: event.target.value,
                  };
                  setFirstName(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                type="text"
                label="Representative Last Name"
                error={formState.errors['representativeLastName']}
                registration={register('representativeLastName')}
                name="representativeLastName"
                value={lastName}
                onChange={(event) => {
                  formState.errors['representativeLastName'] = '';
                  setValue('representativeLastName', event.target.value);
                  const newValues = {
                    representativeLastName: event.target.value,
                  };
                  setLastName(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <div className="mb-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                  Representative Phone Number
                </div>
                <PhoneInput
                  country={'ug'}
                  countryCodeEditable={false}
                  masks={{ ug: '... ... ...' }}
                  name="representativePhoneNumber"
                  value={representativePhoneNumber}
                  inputStyle={{
                    width: '100%',
                    height: '60px',
                    fontSize: '1rem',
                    border: '1px solid #e5e5e5',
                    borderRadius: '8px',
                  }}
                  containerClass="mt-2"
                  onChange={(phone) => {
                    formState.errors['representativePhoneNumber'] = '';
                    setValue('representativePhoneNumber', `+${phone}`);
                    setRepresentativePhoneNumber(`+${phone}`);
                    const newValues = {
                      representativePhoneNumber: `+${phone}`,
                    };
                    const existingValues =
                      JSON.parse(localStorage.getItem('companyRegister')) || {};
                    // Merge existing and new values
                    const mergedValues = {
                      ...existingValues,
                      ...newValues,
                    };
                    // Save merged values back to storage
                    localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                  }}
                />
                {formState.errors['representativePhoneNumber']?.message && (
                  <div
                    role="alert"
                    aria-label={formState.errors['representativePhoneNumber']?.message}
                    className="text-sm font-semibold text-red-500"
                  >
                    {formState.errors['representativePhoneNumber']?.message}
                  </div>
                )}
              </div>
              <InputField
                type="email"
                label="Representative Email Address"
                error={formState.errors['representativeEmailAddress']}
                registration={register('representativeEmailAddress')}
                name="representativeEmailAddress"
                value={representativeEmailAddress}
                onChange={(event) => {
                  formState.errors['representativeEmailAddress'] = '';
                  setValue('representativeEmailAddress', event.target.value);
                  const newValues = {
                    representativeEmailAddress: event.target.value,
                  };
                  setRepresentativeEmailAddress(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              <InputField
                label="Representative NIN"
                error={formState.errors['representativeNIN']}
                registration={register('representativeNIN')}
                name="representativeNIN"
                value={representativeNIN}
                onChange={(event) => {
                  formState.errors['representativeNIN'] = '';
                  setValue('representativeNIN', event.target.value);
                  const newValues = {
                    representativeNIN: event.target.value,
                  };
                  setRepresentativeNIN(event.target.value);
                  const existingValues = JSON.parse(localStorage.getItem('companyRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('companyRegister', JSON.stringify(mergedValues));
                }}
              />
              {loginErr && (
                <div
                  role="alert"
                  aria-label={loginErr}
                  className="text-sm font-semibold text-red-500"
                >
                  {loginErr}
                </div>
              )}
              <div className="">
                By signing up, you are agreeing to the{' '}
                <Link to="/general-policy" className="text-primary-100">
                  General Policy for Lenders and Borrowers
                </Link>
                . This site uses essential cookies. See our Cookie Notice for more information.
              </div>

              <div>
                <Button
                  isLoading={registerCompanyMutation.isLoading}
                  type="submit"
                  className="w-full"
                >
                  Sign Up
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          Already have an account? {''}
          <Link to="../login" className="font-medium text-primary-100 hover:text-blue-500">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
