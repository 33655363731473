export const formatDateToISO8601 = (isoDate) => {
  // Create a new Date object from the ISO date string
  const date = new Date(isoDate);

  // Get the day, month, and year from the date object
  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'long' }).toUpperCase();
  const year = date.getUTCFullYear();

  // Function to get the ordinal suffix for a day
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'TH'; // catch 11th, 12th, 13th
    switch (day % 10) {
      case 1:
        return 'ST';
      case 2:
        return 'ND';
      case 3:
        return 'RD';
      default:
        return 'TH';
    }
  }

  // Format the date in the desired format
  const readableDate = `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;

  // Return the formatted date
  return readableDate;
};
