import { Table, Spinner } from 'components/Elements';
import { formatDate } from 'utils/format';

import { useUsers } from '../api/getUsers';

import { DeleteUser } from './DeleteUser';

import { Slash } from 'react-feather';

export const UsersList = () => {
  const usersQuery = useUsers();

  if (usersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!usersQuery.data) return null;

  return (
    <Table
      data={usersQuery.data}
      columns={[
        {
          title: 'Name',
          field: 'basicInformation',
          Cell({ entry: { basicInformation } }) {
            return (
              <>
                <span className="text-lg font-bold">
                  {basicInformation.firstName} {basicInformation.lastName}
                  {basicInformation.companyName ?? basicInformation.companyName}
                </span>
                <br />
                {basicInformation.firstName ? 'Personal Account' : 'Company Account'}
              </>
            );
          },
        },
        {
          title: 'Contact Information',
          field: 'basicInformation',
          Cell({ entry: { basicInformation } }) {
            return (
              <>
                {basicInformation.contactInformation?.email}
                <br />
                {basicInformation.contactInformation?.phoneNumber}
              </>
            );
          },
        },
        {
          title: 'Status',
          field: 'status',
        },
        {
          title: 'Last Used',
          field: 'createdAt',
          Cell({ entry: { createdAt } }) {
            return <span>{formatDate(createdAt)}</span>;
          },
        },
        {
          title: 'action',
          field: '_id',
          Cell({ entry: { _id } }) {
            return (
              <div className="flex flex-row items-center">
                <DeleteUser id={_id} />
                <button>
                  <Slash size={20} />
                </button>
              </div>
            );
          },
        },
      ]}
    />
  );
};
