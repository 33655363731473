import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';

const Breadcrumbs = ({ routes }) => {
  return (
    <nav
      className="flex bg-white border border-gray-200 p-2 rounded-full px-4 mt-4 w-full"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center text-gray-800">
          <Link
            to="/app"
            className="inline-flex items-center text-sm  text-gray-800 hover:text-gray-900 dark:text-gray-800 dark:hover:text-gray-200"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            Home
          </Link>
        </li>
        {routes?.map((route, index) => {
          return (
            <li className="inline-flex items-center" key={index}>
              <ChevronRight size={10} className="mr-4" />
              <Link
                to={route.path}
                className="inline-flex items-center text-sm font-extrabold text-gray-700 hover:text-gray-900 dark:text-gray-800 dark:hover:text-gray-200"
              >
                {route.name}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
