import { Button, Dialog, DialogTitle } from 'components/Elements';
import React from 'react';
import { Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { useRequestVerification } from '../api/requestVerification';
import { Verify } from '../';

export function VerifyPhoneNumber() {
  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();

  const requestVerificationMutation = useRequestVerification();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="inverseSecondary">
        Verify Phone Number
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          {requestVerificationMutation.isSuccess ? (
            <>
              <Verify close={handleClose} />
            </>
          ) : (
            <Form
              id="lend-now"
              onSubmit={async () => {
                await requestVerificationMutation.mutateAsync();
              }}
            >
              {() => (
                <>
                  <DialogTitle className="text-xl text-center font-bold mb-4">
                    Verify Phone Number
                  </DialogTitle>
                  <>
                    You are required to verify your phone number before you can make any loan
                    offers.
                  </>
                  <div className="flex flex-row items-center space-x-2">
                    <Button
                      onClick={handleClose}
                      type="button"
                      className="w-full"
                      variant="inverseSecondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      isLoading={requestVerificationMutation.isLoading}
                      className="w-full"
                      variant="secondary"
                    >
                      Request Verification
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </div>
      </Dialog>
    </div>
  );
}
