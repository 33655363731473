import { Form, InputField } from 'components/Form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useApplyExecutiveGuarantee } from '../api/applyExecutiveGuarantee';
import { Link } from 'react-router-dom';
import { Button } from 'components/Elements';

export const ExecutiveGuarantee = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState('');

  const applyForExecutiveGuaranteeMutation = useApplyExecutiveGuarantee();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <WebLayout>
      <Head
        title="Apply For Executive Guarantee"
        description="
        Enabling Finance Limited - Apply For Executive Guarantee
      "
      />
      <div className="py-16">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-16">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-800">Apply For Executive Guarantee</h2>
          </div>
          <div className="mx-8 md:mx-48">
            <Form
              onSubmit={async (values) => {
                values.termsAndConditionsAccepted = true;
                values.privacyPolicyAccepted = true;
                values.phoneNumber = phoneNumber;
                await applyForExecutiveGuaranteeMutation.mutateAsync({
                  data: values,
                });
                navigate('/executive-guarantee/success');
              }}
            >
              {({ register, formState }) => (
                <>
                  <InputField
                    type="text"
                    label="First Name"
                    error={formState.errors['firstName']}
                    registration={register('firstName')}
                  />
                  <InputField
                    type="text"
                    label="Last Name"
                    error={formState.errors['lastName']}
                    registration={register('lastName')}
                  />
                  <div className="mb-3">
                    <div className="text-sm">Phone Number</div>

                    <PhoneInput
                      country={'ug'}
                      value={phoneNumber}
                      inputStyle={{
                        width: '100%',
                        height: '60px',
                        fontSize: '1rem',
                        border: '1px solid #e5e5e5',
                        borderRadius: '8px',
                      }}
                      onChange={(phone) => setPhoneNumber(`+${phone}`)}
                    />
                  </div>
                  <InputField
                    type="email"
                    label="Email Address"
                    error={formState.errors['email']}
                    registration={register('email')}
                  />
                  <InputField
                    type="text"
                    label="National Identification Number"
                    error={formState.errors['nin']}
                    registration={register('nin')}
                    maxLength={14}
                  />
                  <div className="">
                    By applying, you are agreeing to the{' '}
                    <Link to="/general-policy" className="text-primary-100">
                      General Policy for Lenders and Borrowers
                    </Link>
                    . This site uses essential cookies. See our Cookie Notice for more information.
                  </div>

                  <div>
                    <Button
                      isLoading={applyForExecutiveGuaranteeMutation.isLoading}
                      type="submit"
                      className="w-full"
                    >
                      Submit Application
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
