import { ForgotPasswordForm } from "../components/ForgotPasswordForm";
import { Layout } from "../components/Layout";

export const ForgotPassword = () => {
  return (
    <Layout title="Forgot your password?">
      <ForgotPasswordForm />
    </Layout>
  );
};
