import React from 'react';
import { Table, Spinner } from 'components/Elements';
import { useAssets } from '../api/getAssets';
import { Eye } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteAsset } from './DeleteAsset';
import { formatText } from 'utils/formatText';
import { UPLOADS_URL } from 'config';

export const AssetsList = () => {
  const assetsQuery = useAssets();
  const navigate = useNavigate();

  if (assetsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetsQuery.data) return null;

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {assetsQuery.data.map((asset, index) => (
          <div
            className="bg-white rounded-3xl shadow p-4 hover:transform hover:scale-105 transition-transform"
            key={index}
          >
            <Link className="flex flex-col" to={`./${asset._id}`}>
              <img
                src={`${UPLOADS_URL}/collateral/${asset?.pictures && asset?.pictures[0]}`}
                alt={asset.title}
                className="w-full h-40 object-cover rounded-lg"
              />
              <div className="font-bold text-center mt-2 capitalize">{asset.title}</div>
              <div className="text-center text-xs mb-2">{formatText(asset.category)}</div>
              <span className="bg-orange-100 text-orange-500 text-center rounded-full p-1 py-3 px-2">
                Current Worth: UGX {asset?.current_worth?.toLocaleString()}
              </span>
            </Link>
            <div className="my-2 flex flex-row items-center justify-center">
              <DeleteAsset id={asset._id} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
