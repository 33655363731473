import React from 'react';
import { FieldWrapper } from './FieldWrapper';

export const DocumentUploadField = (props) => {
  const { onChange, label, accept, error, registration, required } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <input
        type="file"
        accept={accept}
        name="file"
        className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        onChange={onChange}
        required={required}
        {...registration}
      />
    </FieldWrapper>
  );
};
