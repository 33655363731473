import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { AssetsList } from '../components/AssetsList';
import { FilePlus, Plus } from 'react-feather';

export const Assets = () => {
  const routes = [
    {
      name: 'My Collaterals',
      path: '/app/assets',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="My Collaterals"
      end={
        <Button
          size="sm"
          onClick={() => navigate('/app/assets/create')}
          startIcon={<Plus size={16} />}
        >
          New Asset
        </Button>
      }
    >
      <Breadcrumbs routes={routes} />

      <div className="mt-8">
        <AssetsList />
      </div>
    </ContentLayout>
  );
};
