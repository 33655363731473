import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField, TextAreaField, UploadField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';
import Swal from 'sweetalert2';

import { useUpdateAvatar } from '../api/updateAvatar';
import { useAuth } from 'lib/auth';

export const UpdateAvatar = ({ id }) => {
  const [avatar, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const updateAvatarMutation = useUpdateAvatar();

  const { user } = useAuth();

  if (updateAvatarMutation.isLoading) return <FullScreenSpinner />;

  return (
    <>
      <FormDrawer
        isDone={updateAvatarMutation.isSuccess}
        triggerButton={
          <Button variant="inverse" startIcon={<PencilIcon className="h=8" />}>
            Edit
          </Button>
        }
        title="Update Profile Photo"
        submitButton={
          <Button
            form="update-avatar"
            type="submit"
            size="sm"
            isLoading={updateAvatarMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-avatar"
          onSubmit={async (values) => {
            const bodyFormData = new FormData();
            bodyFormData.append('avatar', avatar);
            await updateAvatarMutation.mutateAsync({ data: bodyFormData, id });
            setPreview(null);
          }}
          options={{
            defaultValues: {
              avatar: user.basicInformation?.avatar,
            },
          }}
        >
          {({ register, formState }) => (
            <>
              <UploadField
                title="Profile picture"
                error={formState.errors['avatar']}
                caption="A picture helps improve your credit rating and let's lenders recognize you."
                button={
                  <button
                    type="button"
                    onClick={() => {
                      setPreview(null);
                    }}
                    className="text-red-500 cursor-pointer"
                  >
                    Remove
                  </button>
                }
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
                preview={preview}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};

UpdateAvatar.propTypes = {
  id: PropTypes.string.isRequired,
};
