import { Layout } from '../components/Layout';

export const ConfirmAccount = () => {
  return (
    <Layout title="Please confirm your Email Address">
      <div className="mt-4">
        An email has been sent to your email address containing a confirmation link. Please confirm
        your email address to activate your finablr user account.
      </div>
      <div className="mt-3 text-center">Thank you</div>
    </Layout>
  );
};
