import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const RegularTextAreaField = (props) => {
  const {
    rows = 3,
    label,
    className,
    disabled,
    value,
    registration,
    caption,
    placeholder,
    onChange,
    error,
    maxLength,
  } = props;

  return (
    <FieldWrapper label={label} error={error}>
      <textarea
        id="message"
        rows={rows}
        className={clsx(
          'appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        value={value}
        // {...registration}
        onChange={onChange}
      ></textarea>
      <div className="my-1 text-xs flex justify-end">{caption}</div>
    </FieldWrapper>
  );
};
