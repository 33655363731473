import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import { Mail, MapPin, Phone } from 'react-feather';
import { Button } from 'components/Elements';

export const ContactUs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const address = `P.O BOX, 134920 KAMPALA, UGANDA\nPLOT 14051, TWIN PLAZA, MBOGO ROAD-KIRA,\nOPPOSITE POSH PETROL STATION`;
  const toEmail = 'operations@enablingfinancelimited.com';
  const officeTel = '+256706400057';

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:${toEmail}?subject=Contact%20Form&body=Name:%20${encodeURIComponent(
      name
    )}%0D%0AEmail:%20${encodeURIComponent(email)}%0D%0AMessage:%20${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <WebLayout>
      <Head title="Contact Us" description="Enabling Finance Limited" />
      <div className="container mx-auto py-12 px-6">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-semibold text-gray-800">Get in Touch</h1>
          <p className="mt-2 text-lg text-gray-600">We'd love to hear from you!</p>
        </div>

        <div className="mx-12 md:mx-48 flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
            <MapPin className="w-8 h-8 text-primary-100 mb-2" />
            <address className="text-gray-700 text-center md:text-left">
              <p>
                {address.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            </address>
          </div>

          <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
            <Mail className="w-8 h-8 text-primary-100 mb-2" />
            <a href={`mailto:${toEmail}`} className="text-primary-100">
              {toEmail}
            </a>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <Phone className="w-8 h-8 text-primary-100 mb-2" />
            <a href={`tel:${officeTel}`} className="text-primary-100">
              {officeTel}
            </a>
          </div>
        </div>

        <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
          <label className="block mb-2 text-gray-700" htmlFor="name">
            Name
          </label>
          <input
            className="border border-gray-300 rounded-md px-4 py-2 mb-4 w-full"
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label className="block mb-2 text-gray-700" htmlFor="email">
            Email
          </label>
          <input
            className="border border-gray-300 rounded-md px-4 py-2 mb-4 w-full"
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className="block mb-2 text-gray-700" htmlFor="message">
            Message
          </label>
          <textarea
            className="border border-gray-300 rounded-md px-4 py-2 mb-6 w-full"
            id="message"
            name="message"
            rows="4"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

          <Button type="submit" className="w-full">
            Send Message
          </Button>
        </form>

        <div className="mt-12">
          <h2 className="text-2xl font-bold mb-2 text-center">Connect with Us</h2>
          <div className="flex justify-center">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 mx-2"
            >
              <FaFacebook className="w-10 h-10" />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-500 mx-2"
            >
              <FaTwitter className="w-10 h-10" />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-600 hover:text-pink-700 mx-2"
            >
              <FaInstagram className="w-10 h-10" />
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-800 mx-2"
            >
              <FaLinkedin className="w-10 h-10" />
            </a>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
