import { Spinner } from 'components/Elements';
import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const BorrowerPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <WebLayout>
      <div className="mx-24 mt-12">
        <div className="font-semibold text-3xl text-center">
          Finablr Borrower Policy/Agreement_January 2023
        </div>
      </div>
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vTPrWi2ccLjcc-e5AqkuOiDw0fFBSmSdSO-5CVMMPx4qTy1z1vVpIUQeobn5FKmrAxLhRPpnPioQx0c/pub?embedded=true"
        width="100%"
        height="800px"
        frameBorder="0"
        scrolling="yes"
        title="Finablr Borrower Policy/Agreement_January 2023"
        onLoad={<Spinner />}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </WebLayout>
  );
};
