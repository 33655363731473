import { Navigate, Route, Routes } from 'react-router-dom';

import { MyLoan } from './MyLoan';
import { Offers } from './Offers';
import { RepaymentSchedule } from './RepaymentSchedule';

export const MyLoanRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<MyLoan />} />
      <Route path="view-offers" element={<Offers />} />
      <Route path="repayment-schedule" element={<RepaymentSchedule />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
