import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const addToWishlist = ({ data }) => {
  return axios.post(`/wishlists/add`, data);
};

addToWishlist.propTypes = {
  data: PropTypes.shape({
    loanId: PropTypes.string.isRequired,
  }).isRequired,
};

export const useAddToWishlist = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newPost) => {
      await queryClient.cancelQueries('wishlist');

      const previousPosts = queryClient.getQueryData('wishlist');

      queryClient.setQueryData('wishlist', [...(previousPosts || []), newPost.data]);

      return { previousPosts };
    },
    onError: (_, __, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData('wishlist', context.previousPosts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('wishlist');
      addNotification({
        type: 'success',
        title: 'Added to wishlist',
      });
    },
    ...config,
    mutationFn: addToWishlist,
  });
};
