import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const acceptOffer = ({ data, id }) => {
  return axios.post(`/loans/accept-offer/${id}`, data);
};

export const useAcceptOffer = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingOffer) => {
      await queryClient.cancelQueries(['offer', updatingOffer?.id]);

      const previousOffer = queryClient.getQueryData[('offer', updatingOffer?.id)];

      queryClient.setQueryData(['offer', updatingOffer?.id], {
        ...previousOffer,
        ...updatingOffer.data,
        id: updatingOffer.id,
      });

      return { previousOffer };
    },
    onError: (_, __, context) => {
      if (context?.previousOffer) {
        queryClient.setQueryData(['offer', context.previousOffer.id], context.previousOffer);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['offer', data._id]);
      addNotification({
        type: 'success',
        title: 'Offer accepted',
      });
    },
    ...config,
    mutationFn: acceptOffer,
  });
};
