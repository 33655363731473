import React from 'react';
import HuraayImg from 'assets/huraay.svg';
import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';

export const Success = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto">
      <div className="bg-white rounded-2xl shadow p-6 mt-12 mx-4 md:mx-24 mb-12">
        <img src={HuraayImg} alt="Huraay" className=" mx-auto" />
        <h1 className="text-3xl text-center mt-8">Success!</h1>
        <p className="text-center mt-4">
          You’ve successfully added a collateral asset to your listings. Feel free to apply for a
          loan now.
        </p>
        <div className="flex flex-row justify-center mt-4">
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:items-center md:space-x-2">
            <Button onClick={() => navigate('/app')}>Home</Button>
            <Button variant="outline" onClick={() => navigate('/app/assets/create')}>
              Add another asset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
