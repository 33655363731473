import { formatRelativeTime } from 'utils/formatRelativeTime';

export const AuditLog = ({ comments }) => {
  return (
    <>
      <div className="bg-white rounded-2xl shadow mt-8">
        <div className="p-4 font-bold">Loan Audit Log</div>
        <hr />
        {comments.map((comment, index) => (
          <>
            <div className="p-4 text-sm">
              <div className="flex flex-row items-center justify-between">
                <div className="">{comment.comment}</div>
                <div className="">{formatRelativeTime(comment.date)}</div>
              </div>
              <div className="">{comment.username}</div>
            </div>
            <hr />
          </>
        ))}
      </div>
    </>
  );
};
