import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getMyLoan = () => {
  return axios.get('/loans/my-loan');
};

export const useMyLoan = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['myLoan'],
    queryFn: () => getMyLoan(),
  });
};
