import { axios } from 'lib/axios';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const requestActivateAccount = () => {
  return axios.post(`/users/activate`);
};

export const useRequestActivateAccount = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: () => {},
    ...config,
    mutationFn: requestActivateAccount,
  });
};
