export const defaults = {
  RAMSize: [
    { label: '1 - 2 GB', value: '1-2' },
    { label: '2 - 4 GB', value: '2-4' },
    { label: '4 - 6 GB', value: '4-6' },
    { label: '6+ GB', value: '6+' },
  ],
  storageSpace: [
    { value: '16-32', label: '16-32' },
    { value: '32-64', label: '32-64' },
    { value: '64-128', label: '64-128' },
    { value: '128-256', label: '128-256' },
    { value: '500-1TB', label: '500-1TB' },
    { value: '1TB+', label: '1TB+' },
  ],
  tvBrand: [
    { label: 'Samsung', value: 'SAMSUNG' },
    { label: 'Apple', value: 'APPLE' },
    { label: 'Huawei', value: 'HUAWEI' },
    { label: 'Xiaomi', value: 'XIAOMI' },
    { label: 'Oppo', value: 'OPPO' },
    { label: 'Vivo', value: 'VIVO' },
    { label: 'Tecno', value: 'TECNO' },
    { label: 'Hisense', value: 'HISENSE' },
    { label: 'Nokia', value: 'NOKIA' },
    { label: 'Sony', value: 'SONY' },
    { label: 'LG', value: 'LG' },
    { label: 'Motorola', value: 'MOTOROLA' },
    { label: 'OnePlus', value: 'ONEPLUS' },
    { label: 'Google', value: 'GOOGLE' },
    { label: 'Blackberry', value: 'BLACKBERRY' },
    { label: 'Other', value: 'OTHER' },
  ],
  tvDimensions: [
    { label: '24', value: '24' },
    { label: '32', value: '32' },
    { label: '40', value: '40' },
    { label: '43', value: '43' },
    { label: '50', value: '50' },
    { label: '55', value: '55' },
    { label: '65', value: '65' },
    { label: '75', value: '75' },
    { label: '85', value: '85' },
    { label: '100', value: '100' },
  ],
  isSmartTv: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  computerBrand: [
    { label: 'Apple', value: 'APPLE' },
    { label: 'Dell', value: 'DELL' },
    { label: 'HP', value: 'HP' },
    { label: 'Lenovo', value: 'LENOVO' },
    { label: 'Acer', value: 'ACER' },
    { label: 'Asus', value: 'ASUS' },
    { label: 'Microsoft', value: 'MICROSOFT' },
    { label: 'Toshiba', value: 'TOSHIBA' },
    { label: 'Samsung', value: 'SAMSUNG' },
    { label: 'LG', value: 'LG' },
    { label: 'Sony', value: 'SONY' },
    { label: 'Other', value: 'OTHER' },
  ],
  computerProcessor: [
    { value: '', label: 'Select..' },
    { value: '1-2', label: '1-2' },
    { value: '2-3', label: '2-3' },
    { value: '3+', label: '3+' },
  ],
  computerHarddisk: [
    { value: '', label: 'Select..' },
    { value: '100-250', label: '100-250' },
    { value: '260-500', label: '260-500' },
    { value: '500-1TB', label: '500-1TB' },
    { value: '1TB+', label: '1TB+' },
  ],
  computerHardDiskType: [
    { value: '', label: 'Select..' },
    { value: 'HDD', label: 'HDD' },
    { value: 'SSD', label: 'SSD' },
  ],
  electronics: [
    { label: 'Refrigerator', value: 'REFRIGERATOR' },
    { label: 'Washing Machine', value: 'WASHING_MACHINE' },
    { label: 'Gaming Console', value: 'GAMING_CONSOLE' },
    { label: 'Cooker', value: 'COOKER' },
    { label: 'Oven', value: 'OVEN' },
    { label: 'Microwave', value: 'MICROWAVE' },
    { label: 'Others', value: 'OTHERS' },
  ],
  type_of_title: [
    { label: 'Private Mailo', value: 'PRIVATE_MAILO' },
    { label: 'Freehold', value: 'FREEHOLD' },
    { label: 'Leasehold', value: 'LEASEHOLD' },
  ],
  size_of_land: [
    { label: '50*100ft (12.5 decimals)', value: '12_5_DECIMALS' },
    {
      label: '50*100ft to half acre (12.5 - 50 decimals)',
      value: '12_5_50_DECIMALS',
    },
    {
      label: 'Greater than half acre (51 decimals +)',
      value: '51_+_DECIMALS',
    },
  ],
  whats_on_land: [
    { label: 'Rentals', value: 'RENTALS' },
    { label: 'Main House', value: 'MAIN_HOUSE' },
    {
      label: 'Main House and Rentals',
      value: 'MAIN_HOUSE_AND_RENTALS',
    },
    { label: 'Apartments', value: 'APARTMENTS' },
    { label: 'Hotel or Bar', value: 'HOTEL_BAR' },
    { label: 'School or Market', value: 'SCHOOL_MARKET' },
    { label: 'Empty Land', value: 'EMPTY_LAND' },
    { label: 'Others', value: 'OTHERS' },
  ],
};
