import { Navigate, Route, Routes } from 'react-router-dom';

import { Product } from './Product';
import { Products } from './Products';
import { CreateProduct } from './CreateProduct';

export const ProductsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Products />} />
      <Route path=":id" element={<Product />} />
      <Route path="new" element={<CreateProduct />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
