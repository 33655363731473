import { Spinner } from 'components/Elements';
import { WebLayout } from 'components/Layout';
import React, { useEffect } from 'react';

export const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <WebLayout>
      <div className="mx-24 mt-12">
        <div className="font-semibold text-3xl text-center">Terms and Conditions</div>
      </div>
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vQ4LbbOSKP3Z1q4F5WfL0WVuvfSHcjCTWyOlMuvNMJc3po8s1goRcuVoS9_h1gLNJWhzLzLuadDj1xs/pub?embedded=true"
        width="100%"
        height="800px"
        frameBorder="0"
        scrolling="yes"
        title="Terms and Conditions"
        onLoad={<Spinner />}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </WebLayout>
  );
};
