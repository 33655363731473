import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/auth';

export const verifyGuarantor = ({ data }) => {
  return axios.post(`/guarantors/verify/confirm`, data);
};

export const useVerifyGuarantor = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      addNotification({
        type: 'success',
        title: data.message,
      });
    },
    ...config,
    mutationFn: verifyGuarantor,
  });
};
