
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { FilePlus } from 'react-feather';
import { UsersList } from '../components/UsersList';
import { Authorization, ROLES } from 'lib/authorization';

export const Users = () => {
  const routes = [
    {
      name: 'Accounts',
      path: '/app/users',
    },
  ];
  const navigate = useNavigate();

  return (
    <ContentLayout
      title="User accounts"
      end={<Button onClick={() => navigate('/app')}>Back Home</Button>}
    >
      <Breadcrumbs routes={routes} />
      <Authorization
          forbiddenFallback={<div>Only admin can view this.</div>}
          allowedRoles={[ROLES.ADMIN]}
        >
      <div className="mt-8 flex flex-row items-center justify-between">
        <input
          type="text"
          className="border border-gray-700 p-2 rounded-xl w-1/2"
          placeholder="Search for first or last name..."
        />
        <Button variant="outline" startIcon={<FilePlus size={16} />}>
          Create New Identity
        </Button>
      </div>
      <div className="mt-8">
        <UsersList />
      </div>
      </Authorization>
    </ContentLayout>
  );
};
