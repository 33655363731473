import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const registerCompany = ({ data }) => {
  return axios.post(`/companies/apply`, data);
};

export const useApplyForCompanyAccount = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (applying) => {
      await queryClient.cancelQueries(['company', applying?.id]);

      const previousCompany = queryClient.getQueryData[('company', applying?.id)];

      queryClient.setQueryData(['company', applying?.id], {
        ...previousCompany,
        ...applying.data,
        id: applying.id,
      });

      return { previousCompany };
    },
    onError: (_, __, context) => {
      if (context?.previousCompany) {
        queryClient.setQueryData(['company', context.previousCompany.id], context.previousCompany);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['company', data._id]);
      addNotification({
        type: 'success',
        title: 'Company Account Application submitted',
      });
    },
    ...config,
    mutationFn: registerCompany,
  });
};
