import { Button } from 'components/Elements';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Success = () => {
  const navigate = useNavigate();
  return (
    <div className="m-8">
      <div className="border bg-white py-24 border-gray-200 rounded-2xl p-4 mb-4">
        <div className="text-3xl text-primary-100 font-bold text-center mb-2">Success!</div>
        <div className="text-gray-600 text-center text-lg mb-3">
          You've successfully submitted your loan request.
        </div>
        <div className="text-sm text-gray-600 text-center">
          Once your request has been reviewed, you'll receive a Due-Deligence checklist in your app
          notifications containing required documents to complete the application for your loan.
        </div>
      </div>
      <Button className="mx-auto" onClick={() => navigate('/app')}>
        Back Home
      </Button>
    </div>
  );
};
