import { InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import PigeonLoansImg from 'assets/pigeon-loans-efl.svg';
import { useTips } from '../api/getTips';
import { Spinner } from 'components/Elements';

export const PigeonTips = () => {
  const tipsQuery = useTips();

  if (tipsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!tipsQuery.data) return null;

  return (
    <div className="bg-primary-100 px-16 h-full rounded-br-3xl pb-12 hidden md:block">
      <div className="mt-48">
        <div className="text-5xl font-bold text-start text-white mb-2">Create your loan</div>
        <div className="text-md text-start text-white">
          Set up your loan with the terms you’re happy with. The lender can always make offers to
          you later.
        </div>
        <div className="mt-12">
          <img src={PigeonLoansImg} alt="Pigeon Loans" className="h-10" />
        </div>

        {/* <div className="mt-24 h-40 rounded-xl bg-primary-200 p-4 text-gray-200 mb-4">
          <div className="text-gray-100 font-semibold text-lg mb-4 flex flex-row items-center space-x-2">
            <InformationCircleIcon className="h-6" />
            <div className="">Loan Tips</div>
          </div>
          <TypeAnimation
            sequence={tipsQuery?.data?.map((tip, index) => tip?.content, 4000)}
            wrapper="span"
            speed={10}
            repeat={Infinity}
            cursor={true}
            aria-expanded={false}
            deletionSpeed={100}
          />
        </div> */}

        <div className="p-4 text-gray-200 text-start text-sm font-semibold flex space-x-2 items-center mb-4">
          <span className="text-xs">Talk to loan advisor?</span>
          <span className="bg-primary-300 rounded-full p-1 px-3 whitespace-nowrap">
            +256 706 400057
          </span>
        </div>
      </div>
    </div>
  );
};
