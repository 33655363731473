import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getContracts = () => {
  return axios.get('/contracts/mine');
};

export const useContracts = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['contracts'],
    queryFn: () => getContracts(),
  });
};
