import { Spinner } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { useWishlist } from '../api/getWishlist';

export const Wishlist = () => {
  const routes = [
    {
      name: 'wishlist',
      path: '/app/wishlist',
    },
  ];

  const wishlistQuery = useWishlist();

  if (wishlistQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!wishlistQuery.data) return null;

  return (
    <>
      <Head title="Wishlist" />
      <ContentLayout title="Wishlist">
        <Breadcrumbs routes={routes} />
        <div className="mt-6"></div>
      </ContentLayout>
    </>
  );
};
