import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const createGuarantor = ({ data }) => {
  return axios.post(`/guarantors`, data);
};

export const useCreateGuarantor = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newLoan) => {
      await queryClient.cancelQueries('guarantors');

      const previousGuarantors = queryClient.getQueryData('guarantors');

      queryClient.setQueryData('guarantors', [...(previousGuarantors || []), newLoan.data]);

      return { previousGuarantors };
    },
    onError: (_, __, context) => {
      if (context?.previousGuarantors) {
        queryClient.setQueryData('guarantors', context.previousGuarantors);
      }
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('guarantors');

      return data;
    },
    ...config,
    mutationFn: createGuarantor,
  });
};
