import {
  Accordion,
  Button,
  Dialog,
  DialogDescription,
  DialogTitle,
  Spinner,
} from 'components/Elements';
import { Form, InputField, RegularTextAreaField } from 'components/Form';
import React from 'react';
import { useSubmitComplaint } from '../api/submitComplaint';
import { useAuth } from 'lib/auth';
import { SupportIcon } from '@heroicons/react/outline';
import SupportSvg from 'assets/support/support.svg';
import AdminSvg from 'assets/support/admin.svg';
import OfficerSvg from 'assets/support/officer.svg';
import FAQSData from './faqs.json';
import { MdClose } from 'react-icons/md';

export function SubmitComplaint() {
  const [show, setShow] = React.useState(false);

  const createComplaintMutation = useSubmitComplaint();

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button
        className="z-10 fixed flex flex-row items-center space-x-2 bottom-4 right-4 bg-gray-800 hover:bg-primary-100 text-white py-2 px-4 rounded-full shadow-lg"
        onClick={handleOpen}
      >
        <SupportIcon className="h-6 w-6" /> <span>Finablr Support</span>
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[90vw] sm:p-12">
          <div className="flex flex-row items-end justify-end">
            <button onClick={handleClose}>
              <MdClose />
            </button>
          </div>
          <Form
            id="create-tip"
            onSubmit={async (values) => {
              if (user) {
                values.email = user.basicInformation?.contactInformation?.email;
              }
              await createComplaintMutation.mutateAsync({ data: values });
            }}
          >
            {({ register, formState }) => (
              <div>
                <DialogTitle className="text-xl text-center font-bold mb-4">
                  Welcome to Finablr Support
                </DialogTitle>
                <>
                  <div className="mb-3">
                    We are happy to have you onboard to the Finablr money lending network. If you
                    have any complaints or suggestions, please feel free to reach out to us. Our
                    teams are growing fast and we are always looking for ways to improve our
                    services.
                  </div>
                  <div className="mb-12">
                    Please reach out to any of the contacts below for the relevant assistance.
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="bg-orange-100 rounded-xl p-8 border border-orange-200 hover:border-orange-500 flex flex-col items-center justify-center">
                      <img src={AdminSvg} alt="Support" className="h-32 mx-auto" />
                      <div className="font-bold text-xl uppercase mb-2 text-orange-500">
                        Admin Support
                      </div>
                      <div className="text-gray-800">+256 755 239459</div>
                      <div className="text-gray-800 text-sm">operations@finablr.app</div>
                    </div>
                    <div className="bg-orange-100 rounded-xl p-8 border border-orange-200 hover:border-orange-500 flex flex-col items-center justify-center">
                      <img src={OfficerSvg} alt="Support" className="h-32 mx-auto" />
                      <div className="font-bold text-xl uppercase mb-2 text-orange-500">
                        Loan Support
                      </div>
                      <div className="text-gray-800">+256 706 400057</div>
                      <div className="text-gray-800 text-sm">
                        operations@enablingfinancelimited.com
                      </div>
                    </div>
                    <div className="bg-orange-100 rounded-xl p-8 border border-orange-200 hover:border-orange-500 flex flex-col items-center justify-center">
                      <img src={SupportSvg} alt="Support" className="h-32 mx-auto" />
                      <div className="font-bold text-xl uppercase mb-2 text-orange-500">
                        Technical Support
                      </div>
                      <div className="text-gray-800">+256 782 692271</div>
                      <div className="text-gray-800 text-sm">jonahgeek@gmail.com</div>
                    </div>
                  </div>
                  <div className="">
                    {/* <div className="">
                      If you'd like to send a complaint or message right away. Please submit below.
                    </div> */}
                    {/* <>
                      {createComplaintMutation.isSuccess ? (
                        <>
                          <div className="">
                            Thank you for your submission. We'll get back to you as soon as we've
                            rectified the issue.
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-4">
                            Please describe the issue you're facing and we'll get back to you as
                            soon as possible.
                          </div>
                          <div className="mx-auto">
                            {!user && (
                              <InputField
                                label="Email Address"
                                error={formState.errors['email']}
                                registration={register('email')}
                                caption="We'll use this to get back to you."
                              />
                            )}
                            <RegularTextAreaField
                              label="Complaint"
                              error={formState.errors['content']}
                              registration={register('content')}
                            />
                          </div>
                        </>
                      )}
                    </>
                    {createComplaintMutation.isSuccess ? (
                      <div className="flex flex-row items-center space-x-2">
                        <button
                          onClick={() => {
                            createComplaintMutation.reset();
                            setShow(false);
                          }}
                          type="button"
                          className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                        >
                          Close
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center space-x-2">
                        <button
                          onClick={handleClose}
                          type="button"
                          className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="mt-4 p-2 px-4  w-full flex flex-row justify-center items-center space-x-2 rounded-2xl text-white bg-primary-100"
                        >
                          {createComplaintMutation.isLoading && <Spinner size="sm" />}
                          <div className="">Submit</div>
                        </button>
                      </div>
                    )} */}
                  </div>
                </>
              </div>
            )}
          </Form>
          <div className="mt-4">
            <div className="font-bold text-xl">FAQs</div>
          </div>
          <div className="">
            {FAQSData.map((faq, index) => (
              <Accordion title={faq.question} key={index}>
                <div className="text-gray-800">{faq.answer}</div>
              </Accordion>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
