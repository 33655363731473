import { useEffect, useState } from 'react';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import 'react-phone-input-2/lib/style.css';

const schema = z.object({
  phoneNumber: z
    .string({
      required_error: 'Phone number is required',
      invalid_type_error: 'Phone number must be a string',
    })
    .min(1, { message: 'Phone number cannot be empty' }),
  password: z
    .string({
      required_error: 'Password is required',
      invalid_type_error: 'Password must be a string',
    })
    .min(1, { message: 'Password cannot be empty' }),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [phoneErr, setPhoneErr] = useState();
  const [loginErr, setLoginErr] = useState('');

  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          //values.phoneNumber = phoneNumber;
          setLoginErr('');
          const data = {
            phoneNumber,
            password: values.password,
          };
          await login(data)
            .then((data) => {
              // console.log('login success', data)
              onSuccess();
            })
            .catch((err) => {
              // console.log('login error', err);
              setLoginErr(`${err.message}. Try again.`);
            });
        }}
        schema={schema}
      >
        {({ register, formState, setValue }) => (
          <>
            {!showPasswordInput ? (
              <div className="flex flex-col space-y-5 mt-4">
                <div className="mb-3">
                  <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                    Phone Number
                  </div>
                  <PhoneInput
                    country={'ug'}
                    countryCodeEditable={false}
                    masks={{ ug: '... ... ...' }}
                    name="phoneNumber"
                    value={phoneNumber}
                    inputStyle={{
                      width: '100%',
                      height: '60px',
                      fontSize: '1rem',
                      border: '1px solid #e5e5e5',
                      borderRadius: '8px',
                    }}
                    containerClass="mt-2"
                    onChange={(phone) => {
                      setPhoneErr('');
                      setLoginErr('');
                      formState.errors['phoneNumber'] = '';
                      setValue('phoneNumber', `+${phone}`);
                      setPhoneNumber(`+${phone}`);
                    }}
                  />
                  {formState.errors['phoneNumber']?.message && (
                    <div
                      role="alert"
                      aria-label={formState.errors['phoneNumber']?.message}
                      className="text-sm font-semibold text-red-500"
                    >
                      {formState.errors['phoneNumber']?.message}
                    </div>
                  )}
                  {phoneErr && (
                    <div
                      role="alert"
                      aria-label={phoneErr}
                      className="text-sm font-semibold text-red-500"
                    >
                      {phoneErr}
                    </div>
                  )}
                </div>

                <div className="mt-2">
                  <Button
                    onClick={() => {
                      if (phoneNumber && phoneNumber.length === 13) {
                        setShowPasswordInput(true);
                        setPhoneErr('');
                      } else {
                        setPhoneErr('Phone number is invalid');
                      }
                    }}
                    className="w-full"
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-4">
                  Sign In
                  {phoneNumber && (
                    <div className="text-sm text-gray-900">Phone Number: {phoneNumber}</div>
                  )}
                </div>
                <InputField
                  type="password"
                  label="Password"
                  name="password"
                  // value={password}
                  passwordField={true}
                  error={formState.errors['password']}
                  registration={register('password')}
                  onChange={(event) => {
                    setLoginErr('');
                    formState.errors['password'] = '';
                    setValue('password', event.target.value);
                    setPassword(event.target.value);
                  }}
                />
                {loginErr && (
                  <div
                    role="alert"
                    aria-label={loginErr}
                    className="text-sm font-semibold text-red-500"
                  >
                    {loginErr}
                  </div>
                )}
                <div>
                  <Button isLoading={isLoggingIn} type="submit" className="w-full">
                    Sign in
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Form>

      <button className="w-full mt-4" onClick={() => navigate('/auth/register')}>
        Don't have an account? <span className="text-primary-100">Sign Up</span>
      </button>
      <button
        className="w-full text-sm text-primary-100"
        onClick={() => navigate('/auth/forgot-password')}
      >
        Forgot Password?
      </button>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
