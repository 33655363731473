import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const approveBorrowerSignature = ({ data, id }) => {
  return axios.post(`/contracts/${id}/approve-signature/borrower`, data);
};

export const useApproveBorrowerSignature = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingContract) => {
      await queryClient.cancelQueries(['contract', updatingContract?.id]);

      const previousContract = queryClient.getQueryData[('contract', updatingContract?.id)];

      queryClient.setQueryData(['contract', updatingContract?.id], {
        ...previousContract,
        ...updatingContract.data,
        id: updatingContract.id,
      });

      return { previousContract };
    },
    onError: (_, __, context) => {
      if (context?.previousContract) {
        queryClient.setQueryData(
          ['contract', context.previousContract.id],
          context.previousContract
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['contract', data.contract?._id]);
      queryClient.refetchQueries(['loan', data.loan?._id]);
      addNotification({
        type: 'success',
        title: 'Thank you. Signature Approved.',
      });
    },
    ...config,
    mutationFn: approveBorrowerSignature,
  });
};
