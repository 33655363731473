import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, InputField, ReactMultiSelectField, SelectField } from 'components/Form';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateProduct } from '../api/createProduct';

export const CreateProduct = () => {
  const routes = [
    {
      name: 'Product Development',
      path: '/app/products',
    },
    {
      name: 'Create Product',
      path: '/app/products/new',
    },
  ];

  const navigate = useNavigate();

  const createProductMutation = useCreateProduct();

  if (createProductMutation.isLoading) return <FullScreenSpinner />;

  return (
    <>
      <ContentLayout
        title="Create New Product"
        end={
          <div className="flex flex-row items-center space-x-2">
            {/* <Button variant="inverse">Save Draft</Button> */}
            <Button onClick={() => navigate('/app')}>Back Home</Button>
          </div>
        }
      >
        <Breadcrumbs routes={routes} />
        <Head title="Create New Product" />
        <Form
          id="create-product"
          onSubmit={async (values) => {
            await createProductMutation.mutateAsync({ data: values });
            navigate('/app/products');
          }}
          className="mt-3"
        >
          {({ register, formState, watch, setValue }) => (
            <div>
              <div className="bg-white rounded-2xl p-4 shadow mb-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Product Title"
                      error={formState.errors['product_title']}
                      registration={register('product_title')}
                    />
                    <ReactMultiSelectField
                      label="Select Acceptable Collaterals"
                      registration={register('acceptable_collaterals')}
                      editorContent={watch('acceptable_collaterals')}
                      setValue={setValue}
                      isMulti={true}
                      name="acceptable_collaterals"
                      defaultValue={watch('acceptable_collaterals')}
                      options={[
                        {
                          label: 'Real Estate With Land Title',
                          value: 'REAL_ESTATE_WITH_LAND_TITLE',
                        },
                        { label: 'Vehicles', value: 'VEHICLES' },
                        { label: 'Computers', value: 'COMPUTER' },
                        { label: 'Television', value: 'TELEVISION' },
                        { label: 'Smart Phones', value: 'SMART_PHONES' },
                        { label: 'Other Electronics', value: 'OTHER_ELECTRONICS' },
                      ]}
                    />
                    <InputField
                      label="Service Fee (UGX)"
                      error={formState.errors['service_fee']}
                      registration={register('service_fee')}
                    />
                    <InputField
                      label="Late Fee (UGX)"
                      error={formState.errors['late_fee']}
                      registration={register('late_fee')}
                    />
                    <InputField
                      label="Early Repayment Fee (UGX)"
                      error={formState.errors['early_repayment_fee']}
                      registration={register('early_repayment_fee')}
                    />
                  </div>
                  <div className="">
                    <div className="grid grid-cols-2 gap-4 ">
                      <InputField
                        label="Principal Minimum (UGX)"
                        error={formState.errors['principal_min']}
                        registration={register('principal_min')}
                      />
                      <InputField
                        label="Principal Maximum (UGX)"
                        error={formState.errors['principal_max']}
                        registration={register('principal_max')}
                      />
                    </div>
                    <InputField
                      label="Fixed Interest (%)"
                      error={formState.errors['fixed_interest']}
                      registration={register('fixed_interest')}
                    />
                    <div className="grid grid-cols-2 gap-4 ">
                      <InputField
                        label="Interest Rate Minimum (%)"
                        error={formState.errors['interest_rate_min']}
                        registration={register('interest_rate_min')}
                      />
                      <InputField
                        label="Interest Rate Maximum (%)"
                        error={formState.errors['interest_rate_max']}
                        registration={register('interest_rate_max')}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4 ">
                      <InputField
                        label="Period Minimum (Months)"
                        error={formState.errors['period_min']}
                        registration={register('period_min')}
                      />
                      <InputField
                        label="Period Maximum (Months)"
                        error={formState.errors['period_max']}
                        registration={register('period_max')}
                      />
                    </div>
                    <InputField
                      label="Average Lending Rate (%)"
                      error={formState.errors['average_lending_rate']}
                      registration={register('average_lending_rate')}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl p-4 shadow mb-3">
                <div className="font-semibold">Salary Loan</div>
              </div>
              <div className="bg-white rounded-2xl p-4 shadow mb-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Salary Collateral (%)"
                      error={formState.errors['salary_collateral_percentage']}
                      registration={register('salary_collateral_percentage')}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl p-4 shadow mb-3">
                <div className="font-semibold">Applicable Fees</div>
              </div>
              <div className="bg-white rounded-2xl p-4 shadow mb-3">
                <div className="font-semibold mb-3">Real Estate</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Due Deligence Fee (UGX)"
                      error={formState.errors['due_deligence_fee']}
                      registration={register('due_deligence_fee')}
                    />
                  </div>
                </div>
                <div className="font-semibold mb-3">Mortgage Placement</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Stamp Duty Fees (UGX)"
                      error={formState.errors['stamp_duty_fees']}
                      registration={register('stamp_duty_fees')}
                    />
                    <InputField
                      label="Registrar Fee (UGX)"
                      error={formState.errors['registrar_fees']}
                      registration={register('registrar_fees')}
                    />
                    <InputField
                      label="Registration Fee (UGX)"
                      error={formState.errors['registration_fees']}
                      registration={register('registration_fees')}
                    />
                  </div>
                  <div className="">
                    <InputField
                      label="Bank Charges (UGX)"
                      error={formState.errors['bank_charges']}
                      registration={register('bank_charges')}
                    />
                    <InputField
                      label="Legal Fees (UGX)"
                      error={formState.errors['legal_fees']}
                      registration={register('legal_fees')}
                    />
                  </div>
                </div>

                <div className="font-semibold mb-3">Vehicles</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Caveat Placement Fee (UGX)"
                      error={formState.errors['caveat_placement_fee']}
                      registration={register('caveat_placement_fee')}
                    />
                    <InputField
                      label="Insurance Fee (UGX)"
                      error={formState.errors['insurance_fee']}
                      registration={register('insurance_fee')}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 ">
                    <InputField
                      label="Tracker (Hire) (UGX)"
                      error={formState.errors['tracker_hire']}
                      registration={register('tracker_hire')}
                    />
                    <InputField
                      label="Tracker (Buy) (UGX)"
                      error={formState.errors['tracker_buy']}
                      registration={register('tracker_buy')}
                    />
                  </div>
                </div>

                <div className="font-semibold mb-3">Valuation for Vehicles</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Personal Vehicles or Bikes (UGX)"
                      error={formState.errors['valuation_personal_vehicles_or_bikes']}
                      registration={register('valuation_personal_vehicles_or_bikes')}
                    />
                    <InputField
                      label="Heavy Vehicles (UGX)"
                      error={formState.errors['valuation_heavy_vehicles']}
                      registration={register('valuation_heavy_vehicles')}
                    />
                  </div>
                  <div className="">
                    <InputField
                      label="Commercial Vehicles (UGX)"
                      error={formState.errors['valuation_commercial_vehicles']}
                      registration={register('valuation_commercial_vehicles')}
                    />
                  </div>
                </div>

                <div className="font-semibold mb-3">Other Assessment Fees</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <InputField
                      label="Computer Assessment Fees (UGX)"
                      error={formState.errors['assessment_fees_computers']}
                      registration={register('assessment_fees_computers')}
                    />
                    <InputField
                      label="Television Assessment Fees (UGX)"
                      error={formState.errors['assessment_fees_television']}
                      registration={register('assessment_fees_television')}
                    />
                  </div>
                  <div className="">
                    <InputField
                      label="Smart Phones Assessment Fees (UGX)"
                      error={formState.errors['assessment_fees_smart_phone']}
                      registration={register('assessment_fees_smart_phone')}
                    />
                    <InputField
                      label="Other Electronics Assessment Fees (UGX)"
                      error={formState.errors['assessment_fees_other_electronics']}
                      registration={register('assessment_fees_other_electronics')}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-2xl p-4 shadow mb-3">
                <SelectField
                  label="Product Status"
                  registration={register('status')}
                  options={[
                    {
                      label: 'Active',
                      value: 'ACTIVE',
                    },
                    { label: 'Inactive', value: 'INACTIVE' },
                  ]}
                />
              </div>
              <div className="flex flex-row justify-end">
                <div className="flex flex-row items-center space-x-2">
                  <Button type="submit" className="bg-primary-100 text-white">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>
      </ContentLayout>
    </>
  );
};
