import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const createLenderProfile = ({ data }) => {
  return axios.post(`/users/register-lender-account`, data);
};

export const useCreateLenderProfile = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.invalidateQueries('auth-user');
      addNotification({
        type: 'success',
        title: 'Lender Profile created successfully',
      });
    },
    ...config,
    mutationFn: createLenderProfile,
  });
};
