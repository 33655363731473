import React from 'react';
import { useNavigate } from 'react-router-dom';

export const CompanyRequestSubmitted = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">Thank You!</h2>
        <p className="text-gray-700 text-lg mb-6 text-center">
          Your request has been submitted successfully. It will be reviewed by our loan officer
          soon.
        </p>
        <p className="text-gray-700 text-lg mb-6 text-center">
          Once your company is approved to transact on Finablr, you'll receive an email with login
          instructions.
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => navigate('/')}
            className="bg-primary-100 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Go Back Home
          </button>
        </div>
      </div>
    </div>
  );
};
