import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField, ReactSelectField, SelectField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';

import { useUpdateProduct } from '../api/updateProduct';
import { useProduct } from '../api/getProduct';

export const UpdateProduct = ({ id }) => {
  const productQuery = useProduct({ id });
  const updateProductMutation = useUpdateProduct();

  if (updateProductMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN]}>
      <FormDrawer
        isDone={updateProductMutation.isSuccess}
        size="md"
        triggerButton={
          <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
            Update
          </Button>
        }
        title="Update Product"
        submitButton={
          <Button
            form="update-product"
            type="submit"
            size="sm"
            isLoading={updateProductMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-product"
          onSubmit={async (values) => {
            await updateProductMutation.mutateAsync({ data: values });
          }}
          options={{
            defaultValues: {
              product_title: productQuery.data.product_title,
              acceptable_collaterals: productQuery.data.acceptable_collaterals.map((item) => ({
                label: item,
                value: item,
              })),
              service_fee: productQuery.data.service_fee,
              late_fee: productQuery.data.late_fee,
              early_repayment_fee: productQuery.data.early_repayment_fee,
              principal_min: productQuery.data.principal.min,
              principal_max: productQuery.data.principal.max,
              fixed_interest: productQuery.data.fixed_interest,
              interest_rate_min: productQuery.data.interest_rate.min,
              interest_rate_max: productQuery.data.interest_rate.max,
              period_min: productQuery.data.period.min,
              period_max: productQuery.data.period.max,
              average_lending_rate: productQuery.data.average_lending_rate,
              salary_collateral_percentage: productQuery.data.salary_collateral_percentage,
              due_deligence_fee: productQuery.data.applicable_fees.real_estate.due_deligence_fee,
              stamp_duty_fees:
                productQuery.data.applicable_fees.real_estate.mortgage_placement.stamp_duty_fees,
              registration_fees:
                productQuery.data.applicable_fees.real_estate.mortgage_placement.registration_fees,
              bank_charges:
                productQuery.data.applicable_fees.real_estate.mortgage_placement.bank_charges,
              registrar_fees:
                productQuery.data.applicable_fees.real_estate.mortgage_placement.registrar_fees,
              legal_fees:
                productQuery.data.applicable_fees.real_estate.mortgage_placement.legal_fees,
              caveat_placement_fee: productQuery.data.applicable_fees.vehicles.caveat_placement_fee,
              insurance_fee: productQuery.data.applicable_fees.vehicles.insurance_fee,
              tracker_hire: productQuery.data.applicable_fees.vehicles.tracker_fee.hire,
              tracker_buy: productQuery.data.applicable_fees.vehicles.tracker_fee.buy,
              valuation_personal_vehicles_or_bikes:
                productQuery.data.applicable_fees.vehicles.valuation.personal_vehicles_or_bikes,
              valuation_heavy_vehicles:
                productQuery.data.applicable_fees.vehicles.valuation.heavy_vehicles,
              valuation_commercial_vehicles:
                productQuery.data.applicable_fees.vehicles.valuation.commercial_vehicles,
              assessment_fees_computers: productQuery.data.applicable_fees.computers.assessment_fees,
              assessment_fees_television: productQuery.data.applicable_fees.television.assessment_fees,
              assessment_fees_smart_phone: productQuery.data.applicable_fees.smart_phone.assessment_fees,
              assessment_fees_other_electronics:
                productQuery.data.applicable_fees.other_electronics.assessment_fees,
            },
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <div className="mb-8">
              <InputField
                label="Product Title"
                error={formState.errors['product_title']}
                registration={register('product_title')}
              />
              <ReactSelectField
                label="Select Acceptable Collaterals"
                registration={register('acceptable_collaterals')}
                editorContent={watch('acceptable_collaterals')}
                setValue={setValue}
                isMulti={true}
                name="acceptable_collaterals"
                defaultValue={watch('acceptable_collaterals')}
                options={[
                  {
                    label: 'Real Estate With Land Title',
                    value: 'REAL_ESTATE_WITH_LAND_TITLE',
                  },
                  { label: 'Vehicles', value: 'VEHICLES' },
                  { label: 'Computers', value: 'COMPUTER' },
                  { label: 'Television', value: 'TELEVISION' },
                  { label: 'Smart Phones', value: 'SMART_PHONES' },
                  { label: 'Other Electronics', value: 'OTHER_ELECTRONICS' },
                ]}
              />
              <InputField
                label="Service Fee (UGX)"
                error={formState.errors['service_fee']}
                registration={register('service_fee')}
              />
              <InputField
                label="Late Fee (UGX)"
                error={formState.errors['late_fee']}
                registration={register('late_fee')}
              />
              <InputField
                label="Early Repayment Fee (UGX)"
                error={formState.errors['early_repayment_fee']}
                registration={register('early_repayment_fee')}
              />
              <div className="grid grid-cols-2 gap-4 ">
                <InputField
                  label="Principal Minimum (UGX)"
                  error={formState.errors['principal_min']}
                  registration={register('principal_min')}
                />
                <InputField
                  label="Principal Maximum (UGX)"
                  error={formState.errors['principal_max']}
                  registration={register('principal_max')}
                />
              </div>
              <InputField
                label="Fixed Interest (%)"
                error={formState.errors['fixed_interest']}
                registration={register('fixed_interest')}
              />
              <div className="grid grid-cols-2 gap-4 ">
                <InputField
                  label="Interest Rate Minimum (%)"
                  error={formState.errors['interest_rate_min']}
                  registration={register('interest_rate_min')}
                />
                <InputField
                  label="Interest Rate Maximum (%)"
                  error={formState.errors['interest_rate_max']}
                  registration={register('interest_rate_max')}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 ">
                <InputField
                  label="Period Minimum (Months)"
                  error={formState.errors['period_min']}
                  registration={register('period_min')}
                />
                <InputField
                  label="Period Maximum (Months)"
                  error={formState.errors['period_max']}
                  registration={register('period_max')}
                />
              </div>
              <InputField
                label="Average Lending Rate (%)"
                error={formState.errors['average_lending_rate']}
                registration={register('average_lending_rate')}
              />
              <InputField
                label="Salary Collateral (%)"
                error={formState.errors['salary_collateral_percentage']}
                registration={register('salary_collateral_percentage')}
              />
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Real Estate</div>
                <InputField
                  label="Due Deligence Fee"
                  error={formState.errors['due_deligence_fee']}
                  registration={register('due_deligence_fee')}
                />
                <div className="font-bold text-lg mb-3">Mortgage Placement</div>
                <InputField
                  label="Stamp Duty Fees"
                  error={formState.errors['stamp_duty_fees']}
                  registration={register('stamp_duty_fees')}
                />
                <InputField
                  label="Registration Fees"
                  error={formState.errors['registration_fees']}
                  registration={register('registration_fees')}
                />
                <InputField
                  label="Bank Charges"
                  error={formState.errors['bank_charges']}
                  registration={register('bank_charges')}
                />
                <InputField
                  label="Registrar Fees"
                  error={formState.errors['registrar_fees']}
                  registration={register('registrar_fees')}
                />
                <InputField
                  label="Legal Fees"
                  error={formState.errors['legal_fees']}
                  registration={register('legal_fees')}
                />
                <InputField
                  label="Bank Charges (UGX)"
                  error={formState.errors['bank_charges']}
                  registration={register('bank_charges')}
                />
              </div>

              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Vehicles</div>
                <InputField
                  label="Caveat Placement Fee"
                  error={formState.errors['caveat_placement_fee']}
                  registration={register('caveat_placement_fee')}
                />
                <InputField
                  label="Insurance Fee"
                  error={formState.errors['insurance_fee']}
                  registration={register('insurance_fee')}
                />
                <div className="font-bold text-lg mb-3">Vehicle Tracker</div>
                <InputField
                  label="Hire"
                  error={formState.errors['tracker_hire']}
                  registration={register('tracker_hire')}
                />
                <InputField
                  label="Buy"
                  error={formState.errors['tracker_buy']}
                  registration={register('tracker_buy')}
                />
                <div className="font-bold text-lg mb-3">Valuation for Vehicles</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
                  <div className="">
                    <InputField
                      label="Personal Vehicles or Bikes (UGX)"
                      error={formState.errors['valuation_personal_vehicles_or_bikes']}
                      registration={register('valuation_personal_vehicles_or_bikes')}
                    />
                    <InputField
                      label="Heavy Vehicles (UGX)"
                      error={formState.errors['valuation_heavy_vehicles']}
                      registration={register('valuation_heavy_vehicles')}
                    />
                  </div>
                  <div className="">
                    <InputField
                      label="Commercial Vehicles (UGX)"
                      error={formState.errors['valuation_commercial_vehicles']}
                      registration={register('valuation_commercial_vehicles')}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Computers</div>
                <InputField
                  label="Computer Assessment Fees (UGX)"
                  error={formState.errors['assessment_fees_computers']}
                  registration={register('assessment_fees_computers')}
                />
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Television</div>
                <InputField
                  label="Television Assessment Fees (UGX)"
                  error={formState.errors['assessment_fees_television']}
                  registration={register('assessment_fees_television')}
                />
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Smart Phone</div>
                <InputField
                  label="Smart Phones Assessment Fees (UGX)"
                  error={formState.errors['assessment_fees_smart_phone']}
                  registration={register('assessment_fees_smart_phone')}
                />
              </div>
              <div className="bg-gray-100 p-4 rounded-md shadow-lg grid grid-cols-1 gap-3 mb-4">
                <div className="font-bold text-xl mb-3">Other Electronics</div>
                <InputField
                  label="Other Electronics Assessment Fees (UGX)"
                  error={formState.errors['assessment_fees_other_electronics']}
                  registration={register('assessment_fees_other_electronics')}
                />
              </div>
              <SelectField
                label="Product Status"
                registration={register('status')}
                options={[
                  {
                    label: 'Active',
                    value: 'ACTIVE',
                  },
                  { label: 'Inactive', value: 'INACTIVE' },
                ]}
              />
            </div>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};
