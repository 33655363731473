import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { Form, UploadField } from 'components/Form';
import React, { useEffect, useState } from 'react';

import { useUpdateAvatar } from '../api/updateAvatar';
import { useAuth } from 'lib/auth';
import { PenTool } from 'react-feather';
import logo from 'assets/logo.svg';

export const UpdateAvatarIcon = ({ requireUpdate }) => {
  console.log(requireUpdate);
  const [show, setShow] = React.useState(false);
  const [avatar, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [nationalIDBack, setNationalIDBack] = useState();
  const [nationalIDFront, setNationalIDFront] = useState();
  const updateAvatarMutation = useUpdateAvatar();

  const { user } = useAuth();

  useEffect(() => {
    if (requireUpdate) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
      <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <DialogTitle className="flex flex-row items-center justify-center font-bold mb-4">
          <img className="h-16" src={logo} alt="Workflow" />
        </DialogTitle>
        <DialogDescription>
          <div>
            <Form
              id="update-avatar"
              onSubmit={async (values) => {
                const bodyFormData = new FormData();
                bodyFormData.append('avatar', avatar);
                bodyFormData.append('nationalIDFront', nationalIDFront);
                bodyFormData.append('nationalIDBack', nationalIDBack);
                await updateAvatarMutation.mutateAsync({ data: bodyFormData, id: user._id });
                setPreview(null);
                setShow(false);
              }}
              options={{
                defaultValues: {
                  avatar: user.basicInformation?.avatar,
                },
              }}
            >
              {({ register, formState }) => (
                <>
                  <UploadField
                    title="Profile picture"
                    error={formState.errors['avatar']}
                    caption="A picture helps improve your credit rating and let's lenders recognize you."
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setPreview(URL.createObjectURL(e.target.files[0]));
                    }}
                    button={
                      <button
                        type="button"
                        onClick={() => {
                          setPreview(null);
                        }}
                        className="text-red-500 cursor-pointer"
                      >
                        Remove
                      </button>
                    }
                    preview={preview}
                  />
                  <div className="">
                    <div className="">
                      <span className="font-semibold text-lg">Upload National ID</span>
                      <br />
                      <span>
                        Please provide clear images so that your loan request may be accepted.
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <UploadField
                      title="Front"
                      error={formState.errors['nationalIDFront']}
                      onChange={(e) => {
                        setNationalIDFront(e.target.files[0]);
                      }}
                    />
                    <UploadField
                      title="Back"
                      error={formState.errors['nationalIDBack']}
                      onChange={(e) => {
                        setNationalIDBack(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <Button variant="inverse" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      isLoading={updateAvatarMutation.isLoading}
                      type="submit"
                      startIcon={<PenTool className="w-4 h-4" />}
                      className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-secondary-100"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </DialogDescription>
      </div>
    </Dialog>
  );
};
