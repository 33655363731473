import React, { useState } from 'react';
import { Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Info } from 'react-feather';

export function CompleteProfile({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} initialFocus={null}>
      <div className="inline-block align-start bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top  sm:p-6">
        <DialogTitle className="text-xl flex flex-col items-center space-y-2 justify-center text-center font-bold mb-4">
          <Info className="h-8 w-8 text-primary-100" />
          <p className="">Complete Your Profile</p>
        </DialogTitle>
      </div>
    </Dialog>
  );
}
