import React, { useState } from 'react';
import Swal from 'sweetalert2';
import * as z from 'zod';
import { Form } from 'components/Form';
import storage from 'utils/storage';

const schema = z.object({
  videoDates: z.string().optional(),
});
export const SelectPaymentMethod = ({ nextStep }) => {
  const [paymentMethod, setPaymentMethod] = useState();

  return (
      <Form
        id="create-loan"
        onSubmit={async () => {
          storage.setFormValues({
            paymentMethod,
          });
          nextStep();
        }}
        schema={schema}
      >
        {() => (
          <div className=" h-screen px-12 md:px-24 pt-12">
            <div className="">
              <div className="text-lg mb-6">Select a payment method</div>
              <button
                type="submit"
                onClick={() => {
                  setPaymentMethod('INTEREST_ONLY');
                }}
                className="bg-white border border-gray-200 shadow w-full text-gray-600 hover:text-white hover:bg-primary-100 rounded-2xl p-8 mb-3"
              >
                <div className="">Interest Only Method</div>
                <div className="text-sm">Recommended for small loan amounts</div>
              </button>
              <button
                type="submit"
                onClick={() => {
                  setPaymentMethod('DECLINING_BALANCE');
                }}
                className="bg-white border border-gray-200 shadow text-gray-600 hover:text-white hover:bg-primary-100 w-full rounded-2xl p-8"
              >
                <div className="">Declining Balance Method</div>
                <div className="text-sm">Recommended for small loan amounts</div>
              </button>
            </div>
          </div>
        )}
      </Form>
  );
};
