import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const updateAsset = ({ data, id }) => {
  return axios.patch(`/collaterals/${id}`, data);
};

export const useUpdateAsset = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingAsset) => {
      await queryClient.cancelQueries(['asset', updatingAsset?.id]);

      const previousAsset = queryClient.getQueryData[('asset', updatingAsset?.id)];

      queryClient.setQueryData(['asset', updatingAsset?.id], {
        ...previousAsset,
        ...updatingAsset.data,
        id: updatingAsset.id,
      });

      return { previousAsset };
    },
    onError: (_, __, context) => {
      if (context?.previousAsset) {
        queryClient.setQueryData(['asset', context.previousAsset.id], context.previousAsset);
      }
    },
    onSuccess: (data) => {
      console.log(data);
      queryClient.refetchQueries(['asset', data._id]);
      addNotification({
        type: 'success',
        title: 'Asset Updated',
      });
    },
    ...config,
    mutationFn: updateAsset,
  });
};
