import { Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Register } from './Register';
import { ConfirmEmail } from './ConfirmEmail';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { CompanyRequestSubmitted } from './CompanyRequestSubmitted';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="register/confirm/:code" element={<ConfirmEmail />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="company-request-submitted" element={<CompanyRequestSubmitted />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
};
