import { create } from 'zustand';
import storage from 'utils/storage';
import { useAuth } from 'lib/auth';
import { useEffect } from 'react';


export const useLiveNotificationStore = create((set) => ({
  notifications: [],
  unreadCount: 0,
  addNotification: (notification) =>
    set((state) => ({
      notifications: [notification, ...state.notifications],
      unreadCount: state.unreadCount + 1,
    })),
  markAsRead: (id) =>
    set((state) => ({
      notifications: state.notifications.map((notification) =>
        notification._id === id ? { ...notification, read: true } : notification
      ),
      unreadCount: state.notifications.filter((n) => n._id !== id && !n.read).length,
    })),
  setNotifications: (notifications) =>
    set((state) => ({
      notifications: notifications,
      unreadCount: notifications.filter((n) => !n.read).length,
    })),
}));

// Hook to setup socket connection and listen for notifications
export const useSetupSocketNotifications = () => {
  const { user } = useAuth();
  const addNotification = useLiveNotificationStore((state) => state.addNotification);

  useEffect(() => {
    
  }, [user]);
};
