export const numToWords = (function () {
  const units = [
    '',
    'ONE',
    'TWO',
    'THREE',
    'FOUR',
    'FIVE',
    'SIX',
    'SEVEN',
    'EIGHT',
    'NINE',
    'TEN',
    'ELEVEN',
    'TWELVE',
    'THIRTEEN',
    'FOURTEEN',
    'FIFTEEN',
    'SIXTEEN',
    'SEVENTEEN',
    'EIGHTEEN',
    'NINETEEN',
  ];
  const tens = [
    '',
    '',
    'TWENTY',
    'THIRTY',
    'FORTY',
    'FIFTY',
    'SIXTY',
    'SEVENTY',
    'EIGHTY',
    'NINETY',
  ];
  const scales = ['', 'THOUSAND', 'MILLION', 'BILLION'];

  function chunkNumber(num) {
    const chunks = [];
    while (num > 0) {
      chunks.push(num % 1000);
      num = Math.floor(num / 1000);
    }
    return chunks;
  }

  function chunkToWords(chunk) {
    const hundred = Math.floor(chunk / 100);
    const rest = chunk % 100;
    const ten = Math.floor(rest / 10);
    const unit = rest % 10;

    let words = [];
    if (hundred > 0) {
      words.push(units[hundred] + ' HUNDRED');
    }
    if (rest > 0) {
      if (rest < 20) {
        words.push(units[rest]);
      } else {
        words.push(tens[ten]);
        if (unit > 0) {
          words.push(units[unit]);
        }
      }
    }
    return words.join(' ');
  }

  return function (num) {
    if (typeof num !== 'number') return 'NOT A NUMBER';
    if (num === 0) return 'ZERO';

    const chunks = chunkNumber(num);
    const chunkWords = chunks
      .map((chunk, index) => {
        if (chunk === 0) return null;
        return chunkToWords(chunk) + (scales[index] ? ' ' + scales[index] : '');
      })
      .filter(Boolean)
      .reverse();

    return chunkWords.join(', ');
  };
})();
