import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { Form, FormDrawer, InputField, SelectField } from 'components/Form';

import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

import { useAsset } from '../api/getAsset';
import { useUpdateAsset } from '../api/updateAsset';

export const UpdateAsset = ({ id }) => {
  const assetQuery = useAsset({ id });

  const updateAssetMutation = useUpdateAsset();

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetQuery.data) return null;

  if (updateAssetMutation.isLoading) return <FullScreenSpinner />;

  return (
    <>
      <FormDrawer
        isDone={updateAssetMutation.isSuccess}
        triggerButton={
          <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
            Edit details
          </Button>
        }
        title="Edit Asset Details"
        submitButton={
          <Button
            form="update-asset"
            type="submit"
            size="sm"
            isLoading={updateAssetMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-asset"
          onSubmit={async (values) => {
            await updateAssetMutation.mutateAsync({ data: values, id });
          }}
          options={{
            defaultValues: {
              title: assetQuery.data?.title,
              description: assetQuery.data?.description,
              size_of_land: assetQuery.data?.real_estate?.size_of_land,
              whats_on_land: assetQuery.data?.real_estate?.whats_on_land,
              land_location: assetQuery.data?.real_estate?.land_location,
              purchase_amount: assetQuery.data?.purchase_amount,
              purchase_date: assetQuery.data?.purchase_date,
              current_worth: assetQuery.data?.current_worth,
            },
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <InputField
                label="Title"
                error={formState.errors['title']}
                registration={register('title')}
              />
              {assetQuery.data.real_estate && (
                <>
                  <InputField
                    label="Size of Land"
                    error={formState.errors['size_of_land']}
                    registration={register('size_of_land')}
                  />
                  <InputField
                    label="What's on Land?"
                    error={formState.errors['whats_on_land']}
                    registration={register('whats_on_land')}
                  />
                  <InputField
                    label="Land Location"
                    error={formState.errors['land_location']}
                    registration={register('land_location')}
                  />
                </>
              )}
              {assetQuery.data.vehicle && (
                <>
                  <InputField
                    label="Brand"
                    error={formState.errors['brand']}
                    registration={register('brand')}
                  />
                  <InputField
                    label="Mileage"
                    error={formState.errors['mileage']}
                    registration={register('mileage')}
                  />
                  <InputField
                    label="Model"
                    error={formState.errors['model']}
                    registration={register('model')}
                  />
                  <SelectField
                    label="Parking Status"
                    registration={register('parking_status')}
                    options={[
                      { label: 'Parked', value: 'PARKED' },
                      { label: 'Not Parked', value: 'NOT_PARKED' },
                    ]}
                  />
                  <InputField
                    label="Year of manufacture"
                    error={formState.errors['year_of_manufacture']}
                    registration={register('year_of_manufacture')}
                  />
                  <InputField
                    label="Color"
                    error={formState.errors['color']}
                    registration={register('color')}
                  />
                </>
              )}
              <InputField
                label="More Details"
                error={formState.errors['description']}
                registration={register('description')}
              />
              <InputField
                label="Purchase Amount"
                error={formState.errors['purchase_amount']}
                registration={register('purchase_amount')}
              />
              <InputField
                label="Purchase Date"
                type="date"
                error={formState.errors['purchase_date']}
                registration={register('purchase_date')}
              />
              <InputField
                label="Current Worth"
                error={formState.errors['current_worth']}
                registration={register('current_worth')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};

UpdateAsset.propTypes = {
  id: PropTypes.string.isRequired,
};
