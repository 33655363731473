import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { PigeonTips } from '../components/PigeonTips';
import { SelectPaymentMethod } from '../components/steps/SelectPaymentMethod';
import { LoanDetails } from '../components/steps/LoanDetails';
import { Guarantor } from '../components/steps/Guarantor';
import { Collateral } from '../components/steps/Collateral';
import { TermsAndConditions } from '../components/steps/TermsAndConditions';
import { Signature } from '../components/steps/Signature';
import { useAuth } from 'lib/auth';
import { Form, InputField, UploadField } from 'components/Form';
import { useUploadFiles } from '../api/uploadFiles';
import { Button } from 'components/Elements';
import Swal from 'sweetalert2';

const schema = z.object({
  avatar: z.string({
    required_error: 'Selfie is required',
    invalid_type_error: 'Selfie must be a string',
  }),
  pigeonLoansNationalIDFront: z.string({
    required_error: 'National ID Front is required',
    invalid_type_error: 'National ID Front must be a string',
  }),
  pigeonLoansNationalIDBack: z.string({
    required_error: 'National ID Back is required',
    invalid_type_error: 'National ID Back must be a string',
  }),
});
export const PigeonLoans = () => {
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState();
  const [preview, setPreview] = useState();
  const [nationalIDBack, setNationalIDBack] = useState();
  const [nationalIDFront, setNationalIDFront] = useState();
  const [canProceed, setCanProceed] = useState(false);
  const [work, setWork] = useState('');

  const { user } = useAuth();

  const uploadFilesMutation = useUploadFiles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getData = async () => {
      const currentLoanStep = localStorage.getItem('pigeonLoansCurrentStep');
      if (currentLoanStep) {
        console.log('current loan step', currentLoanStep);
        if (currentLoanStep !== '1') {
          setStep(parseInt(currentLoanStep));
        }
      }
    };
    getData();
    switch (step) {
      case 1:
        setCanProceed(!!avatar && !!nationalIDFront && !!nationalIDBack); // Example condition for step 1
        break;
      // Add conditions for other steps as needed
      default:
        setCanProceed(true);
    }
  }, [step, avatar, nationalIDFront, nationalIDBack]);

  const goToStep = (stepNumber) => {
    if (stepNumber < step || canProceed) {
      setStep(stepNumber);
    }
  };

  const steps = [
    'Payment Method',
    'Loan Details',
    'Guarantor',
    'Collateral Assets',
    'Terms and Conditions',
    'Signature & Video Call',
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <PigeonTips />
      <div className="bg-white rounded-bl-2xl p-4 shadow md:col-span-2">
        {!user.basicInformation?.avatar || !user.documentVerification?.nationalID?.front ? (
          <div className="m-8">
            <div className="text-3xl text-gray-500 font-bold mb-2">New Loan Application</div>
            <div className="text-lg mb-2">
              Before you proceed, you're required to submit the following;
            </div>
            <ul className="list-disc mx-12 mb-4">
              <li>Your Selfie.</li>
              <li>Front and back images of your identification document.</li>
              <li>A brief description of your work.</li>
            </ul>
            <Form
              id="update-files"
              onSubmit={async (values) => {
                console.log(values);
                const bodyFormData = new FormData();
                if (avatar) bodyFormData.append('avatar', avatar);
                if (nationalIDFront) bodyFormData.append('nationalIDFront', nationalIDFront);
                if (nationalIDBack) bodyFormData.append('nationalIDBack', nationalIDBack);
                bodyFormData.append('work', work);
                const result = await uploadFilesMutation.mutateAsync({
                  data: bodyFormData,
                  id: user._id,
                });
                if (result) {
                  localStorage.setItem('pigeonLoansCurrentStep', '1');
                  setPreview(null);
                  setAvatar(null);
                  setNationalIDFront(null);
                  setNationalIDBack(null);
                  goToStep(1);
                }
              }}
              schema={schema}
            >
              {({ register, formState, setValue }) => (
                <>
                  <UploadField
                    title="Step 1. Provide a Selfie Photo"
                    // error={formState.errors['avatar'].message}
                    caption="A selfie helps improve your credit rating and let's lenders recognize you."
                    onChange={(e) => {
                      setValue('avatar', 'file set');
                      const file = e.target.files[0];
                      if (file && file.type.startsWith('image/')) {
                        setAvatar(file);
                        setPreview(URL.createObjectURL(file));
                      }
                    }}
                    registration={register('avatar')}
                  />
                  <div className="">
                    <div className="">
                      <span className="font-semibold text-lg">Step 2. Identification Document</span>
                      <br />
                      <span className="text-md">National ID / Passport</span>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <UploadField
                        title="Provide a Front image of your national ID"
                        caption="Provide a clear image of the front of your national ID."
                        // error={formState.errors['pigeonLoansNationalIDFront'].message}
                        onChange={(e) => {
                          setValue('pigeonLoansNationalIDFront', 'file set');
                          const file = e.target.files[0];
                          if (file && file.type.startsWith('image/')) {
                            setNationalIDFront(file);
                            console.log('nationalIDFront', nationalIDFront);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <UploadField
                        title="Provide a Back image of your national ID"
                        caption="Provide a clear image of the Back of your national ID."
                        // error={formState.errors['pigeonLoansNationalIDBack'].message}
                        onChange={(e) => {
                          setValue('pigeonLoansNationalIDBack', 'file set');
                          const file = e.target.files[0];
                          if (file && file.type.startsWith('image/')) {
                            setNationalIDBack(file);
                            console.log('nationalIDBack', nationalIDBack);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <span className="font-semibold text-lg">Step 3. Describe your work</span>
                      <br />
                      <span>Please provide a brief description of your work.</span>
                    </div>
                  </div>
                  <InputField
                    error={formState.errors['work']}
                    registration={register('work')}
                    onChange={(e) => {
                      setWork(e.target.value);
                    }}
                  />
                  <div className="flex flex-row justify-end items-center space-x-2">
                    <Button isLoading={uploadFilesMutation.isLoading} type="submit">
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        ) : (
          <>
            <div className="flex justify-center space-x-4 mb-4">
              {steps.map((label, index) => (
                <div key={index} className="flex flex-col items-center space-y-2">
                  <button
                    type="button"
                    className={`flex items-center justify-center h-8 w-8 rounded-full ${
                      step === index + 1 ? 'bg-primary-100 text-white' : 'bg-gray-300 text-gray-500'
                    }`}
                    onClick={() => {
                      const nextStep = index + 1;

                      // Show the Swal alert if the user is clicking the first step and they are not currently on it
                      if (nextStep === 1 && step !== 1) {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: "If you change the payment method, you'll lose your current progress!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, change it!',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // Proceed to reset the form and move to step 1
                            localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                            setStep(nextStep);
                          }
                        });
                      } else if (canProceed || nextStep < step) {
                        // Normal behavior for other steps
                        localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                        setStep(nextStep);
                      }
                    }}
                    disabled={!canProceed && index + 1 > step}
                  >
                    {index + 1}
                  </button>
                  <div className="whitespace-nowrap text-xs hidden md:block">{label}</div>
                </div>
              ))}
            </div>
            {step === 1 && (
              <SelectPaymentMethod
                nextStep={() => {
                  const nextStep = step + 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
              />
            )}
            {step === 2 && (
              <LoanDetails
                nextStep={() => {
                  const nextStep = step + 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
                prevStep={() => {
                  const nextStep = step - 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
              />
            )}
            {step === 3 && (
              <Guarantor
                nextStep={() => {
                  const nextStep = step + 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
                prevStep={() => {
                  const nextStep = step - 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
              />
            )}

            {step === 4 && (
              <Collateral
                nextStep={() => {
                  const nextStep = step + 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
                prevStep={() => {
                  const nextStep = step - 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
              />
            )}
            {step === 5 && (
              <TermsAndConditions
                nextStep={() => {
                  const nextStep = step + 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
                prevStep={() => {
                  const nextStep = step - 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
              />
            )}
            {step === 6 && (
              <Signature
                prevStep={() => {
                  const nextStep = step - 1;
                  localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                  setStep(nextStep);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
