import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/Elements';
import { confirmEmail } from 'features/auth';
import { useAuth } from 'lib/auth';
import storage from 'utils/storage';

export const ActivateAccount = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const { user } = useAuth();

  return (
    <div className="py-24">
      <div className="mt-4 text-center">
        Please click the button below to verify your email address.
      </div>
      <Button
        onClick={async () => {
          await confirmEmail({ code });
          if (user) {
            storage.clearStorage();
            navigate('/auth/login');
          } else {
            navigate('/auth/login');
          }
        }}
        className="mt-4 mx-auto"
      >
        Verify Email Address
      </Button>
    </div>
  );
};
