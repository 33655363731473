import { axios } from 'lib/axios';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const applyForExecutiveGuarantee = ({ data }) => {
  return axios.post(`/users/register-executive`, data);
};

export const useApplyExecutiveGuarantee = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (applying) => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {},
    ...config,
    mutationFn: applyForExecutiveGuarantee,
  });
};
