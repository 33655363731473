import { Button } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import { Link, useNavigate } from 'react-router-dom';

import { useResetPassword } from '../api/resetPassword';
import { useEffect, useState } from 'react';

export const ResetPasswordForm = () => {
  const resetPasswordMutation = useResetPassword();
  const navigate = useNavigate();
  const [token, setToken] = useState('');

  useEffect(() => {
    // Function to extract token from URL
    const extractTokenFromURL = () => {
      // Get the current URL
      const currentUrl = window.location.href;

      // Split the URL by '?' to get the query string
      const queryString = currentUrl.split('?')[1];

      // Split the query string by '&' to get key-value pairs
      const params = queryString.split('&');

      // Initialize a local variable to store the token
      let extractedToken = '';

      // Loop through each key-value pair
      params.forEach((param) => {
        // Split each pair by '=' to get key and value
        const [key, value] = param.split('=');

        // If the key is 'token', store the value as the token
        if (key === 'token') {
          extractedToken = decodeURIComponent(value);
        }
      });

      // Set the extracted token
      setToken(extractedToken);
    };

    // Call the function when the component mounts
    extractTokenFromURL();
  }, []);

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await resetPasswordMutation.mutateAsync({
            data: {
              password: values.password,
              token,
            },
          });
          navigate('/auth/login');
        }}
        // schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              id="password"
              type="password"
              label="New Password"
              placeholder="Password"
              passwordField={true}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button
                isLoading={resetPasswordMutation.isLoading}
                type="submit"
                className="w-full text-gray-g9"
              >
                Reset Password
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <Link to="/auth/login" className="font-medium text-orange-o10 hover:text-blue-500">
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};
