import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const computeNetYouReceive = ({ data }) => {
  return axios.post(`/loans/net-you-receive`, data);
};

// export const useComputeNetYouReceive = ({ config } = {}) => {
//   const { addNotification } = useNotificationStore();
//   return useMutation({
//     onMutate: async () => {},
//     onError : (_, __, context) => {
//       // addNotification({
//       //   title: 'ERROR',
//       //   message: 'Something happened, try again',
//       //   type: 'error',
//       // });
//       console.log('netyourecieve request error')
//     },
//     onSuccess: ({data}) => {
//       // addNotification({
//       //   title: 'Success',
//       //   message: 'Net you receive computed successfully',
//       //   type: 'success',
//       // });
//       return data;
//     },
//     ...config,
//     mutationFn: computeNetYouReceive,
//   });
// };

export const useComputeNetYouReceive = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newLoan) => {
      await queryClient.cancelQueries('net-you-receive');

      const previousLoans = queryClient.getQueryData('net-you-receive');

      queryClient.setQueryData('net-you-receive', [...(previousLoans || []), newLoan.data]);

      return { previousLoans };
    },
    onError: (_, __, context) => {
      if (context?.previousLoans) {
        queryClient.setQueryData('net-you-receive', context.previousLoans);
      }
    },
    onSuccess: ({data}) => {
      queryClient.invalidateQueries('net-you-receive');
      // addNotification({
      //   type: 'success',
      //   title: 'Loan Created',
      // });
      return data;
    },
    ...config,
    mutationFn: computeNetYouReceive,
  });
};
