import logo from 'assets/logo.png';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';

export const AboutUs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <WebLayout>
      <Head title="About Us" description="Enabling Finance Limited" />
      <div className="py-16">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-16">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-800">About Us</h2>
          </div>
          <div className="md:flex justify-between items-start">
            <div className="md:w-1/2 md:pr-8">
              <div className="mb-8">
                <h3 className="text-2xl font-bold text-primary-100 mb-4">Our Vision</h3>
                <p className="text-lg text-gray-700">
                  We envision an Africa where greater social-economic development for all
                  stakeholders is simply a matter of availability of opportunities that are readily
                  backed by a financially inclusive environment built not only on a firm foundation
                  of trust, integrity, professionalism, accountability but also on community.
                </p>
              </div>
              <div className="mb-8">
                <h3 className="text-2xl font-bold text-primary-100 mb-4">Our Story</h3>
                <p className="text-lg text-gray-700">
                  We understand that a key aspect of deep-rooted development in any community
                  especially developing ones such as ours in Uganda is access to credit/capital.
                  Without capital and the skills and knowledge to manage it, one is not only unable
                  to move to the next key step of starting up an enterprise but neither is one able
                  to manage it to greater heights even if one somehow landed on a bag full of cash.
                  Even the mere prospect of seeking employment requires access to credit be it for
                  the purpose of equipping oneself with relevant skills and knowledge through study
                  or actively searching for the employment opportunities. In our early days we had
                  the honor of witnessing, first-hand, the great impact that simple microloans can
                  have on the livelihoods of the few diligent clients we were able to serve. This
                  greatly inspired what would be the genesis of Enabling Finance.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 md:pl-8">
              <div className="mb-8">
                <h3 className="text-2xl font-bold text-primary-100 mb-4">Our Approach</h3>
                <p className="text-lg text-gray-700">
                  Tier IV finance has the unique ability of reaching out to those that for various
                  reasons are unable to presently access finance through the higher Tiers
                  (Commercial banks, Credit Finance Institutions, Microfinance institutions) and yet
                  these happen to be such a great number that a whole Tier (IV) was created for them
                  by government thus allowing us the opportunity to serve.
                </p>
              </div>
              <div className="mb-8">
                <h3 className="text-2xl font-bold text-primary-100 mb-4">Our Mission</h3>
                <p className="text-lg text-gray-700">
                  Through several failures and successes over the years, we have been able to
                  witness two major challenges holding back the Tier IV sector from thriving. These
                  are; unscrupulous lenders that have inspired fear in the hearts of potential
                  clients through their unethical operation but also the dishonest borrowers that
                  borrow with the sale motive and no intention to repay catching less experienced
                  lenders off-guard. The combination of these factors have deprived many, including
                  the genuine borrowers, the opportunity for financial inclusion.
                </p>
              </div>
              <div className="mb-8">
                <h3 className="text-2xl font-bold text-primary-100 mb-4">Our Values</h3>
                <p className="text-lg text-gray-700">
                  It is from this background that the idea of Finablr was born in mid-2017. Through
                  a series of system modifications and innovations based on professionalism,
                  integrity, ethical practice, social-economic empowerment, individual and group
                  accountability but also simply on good business, Finablr seeks to reconcile the
                  relationship between the Tier stakeholders (lenders, borrowers and authorities).
                  This is with the intention of once again reviving the opportunities that a
                  thriving credit network has created in the past and can create again for all
                  parties involved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};
