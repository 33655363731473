import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getContract = ({ id }) => {
  return axios.get(`/contracts/${id}`);
};

export const useContract = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['contract', id],
    queryFn: () => getContract({ id }),
  });
};
