import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, ExportPDF, Spinner } from 'components/Elements';
import { useMyLoan } from '../api/getMyLoan';
import { Check, Printer } from 'react-feather';
import { formatDate } from 'utils/format';

export const RepaymentSchedule = () => {
  const paymentScheduleToPrint = useRef();

  const handlePrint = useReactToPrint({
    content: () => paymentScheduleToPrint.current,
    documentTitle: 'Payment Schedule',
  });

  const myLoanQuery = useMyLoan();

  const routes = [
    {
      name: 'My Loan',
      path: '/app/my-loan',
    },
    {
      name: 'Repayment Schedule',
      path: '/app/my-loan/repayment-schedule',
    },
  ];

  if (myLoanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!myLoanQuery.data) return null;

  console.log(myLoanQuery.data?.payments);

  return (
    <ContentLayout title="Repayment Schedule">
      <div className="flex flex-col md:flex-row md:items-center md:space-x-2 justify-between space-y-4 md:space-y-0">
        <Breadcrumbs routes={routes} />
        <Button size="sm" startIcon={<Printer />} onClick={handlePrint} className="">
          PDF
        </Button>
      </div>
      <div className="mt-3">
        <div ref={paymentScheduleToPrint} className="overflow-x-auto">
          <div className="border border-orange-500 bg-gradient-to-r from-orange-100 to-orange-200 text-orange-600 p-3 rounded-lg text-start mb-3 animate-pulse">
            Important: The repayment dates listed herein are provisional and subject to the formal
            approval and commencement of your loan agreement by Finably Administration. Please await
            confirmation for the final schedule.
          </div>
          <div className="bg-white p-4 shadow-md rounded-xl shadow mb-3">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Installment</th>
                  <th className="px-4 py-2">Principal</th>
                  <th className="px-4 py-2">Interest</th>
                  <th className="px-4 py-2">Late Fees</th>
                  <th className="px-4 py-2">Date Paid</th>
                </tr>
              </thead>
              <tbody>
                {myLoanQuery.data?.payments?.map((payment, index) => (
                  <PaymentItem
                    key={index}
                    installment={payment?.installmentNumber || ''}
                    installmentAmount={payment?.installmentAmount || 0}
                    principalBalance={payment?.principalBalance || 0}
                    interestPaid={payment?.interestPaid || 0}
                    interestBalance={payment?.interestBalance || 0}
                    lateFeesBalance={payment?.lateFeesBalance || 0}
                    lateFeesPaid={payment?.lateFeesPaid || 0}
                    principalPaid={payment?.principalPaid || 0}
                    lateFees={payment?.lateFees || 0}
                    lateBalance={payment?.lateBalance || 0}
                    datePaid={payment?.datePaid || '-'}
                    dateExpected={payment?.dateExpected}
                    status={payment?.status}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

const PaymentItem = ({
  installment,
  installmentAmount,
  principalPaid,
  principalBalance,
  interestPaid,
  interestBalance,
  lateFeesPaid,
  lateFeesBalance,
  datePaid,
  dateExpected,
  status,
}) => {
  return (
    <tr>
      <td className="border">
        {installment !== '' && (
          <div className="ml-4 flex flex-row items-center space-x-3">
            <div
              className={`text-lg text-center rounded-full flex justify-center items-center ${
                status === 'PAID' ? `bg-green-200 border border-green-600` : `bg-gray-200`
              } h-8 w-8`}
            >
              {status === 'PAID' ? <Check size={14} /> : installment}
            </div>
            <div className="">
              <div className="">
                <span className="text-xs">UGX</span>{' '}
                <span className="text-xl">
                  {installmentAmount > 0 && installmentAmount?.toLocaleString()}
                </span>
              </div>
              <div className="text-sm">Scheduled {formatDate(dateExpected)}</div>
            </div>
          </div>
        )}
      </td>
      <td className="border px-2 text-center">
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center">
            <span className="text-end text-xs">Paid</span>{' '}
            <span className="text-end">
              {principalPaid?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <span className="text-end text-xs">Balance</span>{' '}
            <span className="text-end">
              {principalBalance?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
            </span>
          </div>
        </div>
      </td>

      <td className="border px-2 text-center">
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center">
            <span className="text-end text-xs">Paid</span>{' '}
            <span className="text-end">
              {interestPaid?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <span className="text-end text-xs">Balance</span>{' '}
            <span className="text-end">
              {interestBalance?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
            </span>
          </div>
        </div>
      </td>

      <td className="border px-2 text-center">
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center">
            <span className="text-end text-xs">Paid</span>{' '}
            <span className="text-end">
              {lateFeesPaid?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <span className="text-end text-xs">Balance</span>{' '}
            <span className="text-end">
              {lateFeesBalance?.toLocaleString('en-US', { style: 'currency', currency: 'UGX' })}
            </span>
          </div>
        </div>
      </td>

      <td className="border px-2 text-center p-2">
        <span>{datePaid !== '-' && formatDate(datePaid)}</span>
        <br />
        {status === 'IN_REVIEW' && (
          <span className="text-xs text-orange-500 bg-orange-100 border border-orange-500 rounded-full p-1 px-3">
            Pending Review
          </span>
        )}
      </td>
    </tr>
  );
};
