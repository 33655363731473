import React, { useEffect, useState } from 'react';
import { Button } from 'components/Elements';
import { Form, FormDrawer, InputField, PhoneInputField, SelectField } from 'components/Form';
import { useAuth } from 'lib/auth';
import { PencilIcon } from '@heroicons/react/solid';
import * as z from 'zod';

import { useUpdateProfile } from '../api/updateProfile';
import PhoneInput from 'react-phone-input-2';

export const UpdateProfile = () => {
  const { user } = useAuth();
  const updateProfileMutation = useUpdateProfile();
  const [phone, setPhone] = useState();
  useEffect(() => {
    setPhone(user?.basicInformation?.contactInformation?.phoneNumber);
  }, []);

  return (
    <FormDrawer
      isDone={updateProfileMutation.isSuccess}
      size="sm"
      triggerButton={
        <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
          Edit Profile
        </Button>
      }
      title="Edit Profile Details"
      submitButton={
        <Button
          form="update-profile"
          type="submit"
          size="sm"
          isLoading={updateProfileMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form
        id="update-profile"
        onSubmit={async (values) => {
          values.phoneNumber = phone;
          await updateProfileMutation.mutateAsync({ data: values });
        }}
        options={{
          defaultValues: {
            firstName: user?.basicInformation?.firstName,
            lastName: user?.basicInformation?.lastName,
            otherName: user?.basicInformation?.otherName,
            email: user?.basicInformation?.contactInformation?.email,
            phoneNumber: user?.basicInformation?.contactInformation?.phoneNumber,
            residentialAddress: user?.addressInformation?.residentialAddress,
            permanentAddress: user?.addressInformation?.permanentAddress,
            jobTitle: user?.financialInformation?.jobTitle,
            dateOfBirth: user?.basicInformation?.dateOfBirth,
            gender: user?.basicInformation?.gender,
            spouce: user?.basicInformation?.spouce,
            employerName: user?.financialInformation?.employmentDetails?.employerName,
            employerContact: user?.financialInformation?.employmentDetails?.contact,
            monthlyIncome: user?.financialInformation?.monthlyIncome,
            employmentStatus: user?.financialInformation?.employmentStatus,
            tin: user?.financialInformation?.taxIdentificationNumber,
          },
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              label="First Name"
              error={formState.errors['firstName']}
              registration={register('firstName')}
            />
            <InputField
              label="Last Name"
              error={formState.errors['lastName']}
              registration={register('lastName')}
            />
            <InputField
              label="Other Name"
              error={formState.errors['otherName']}
              registration={register('otherName')}
            />

            <PhoneInput
              country={'ug'}
              countryCodeEditable={false}
              masks={{ ug: '... ... ...' }}
              value={phone}
              inputStyle={{
                width: '100%',
                height: '60px',
                fontSize: '1rem',
                border: '1px solid #e5e5e5',
                borderRadius: '8px',
              }}
              onChange={(phone) => setPhone(`+${phone}`)}
            />
            <InputField
              label="Email Address"
              type="email"
              error={formState.errors['email']}
              registration={register('email')}
            />

            <InputField
              label="Spouce"
              error={formState.errors['spouce']}
              registration={register('spouce')}
            />

            <SelectField
              label="Employment Status"
              registration={register('employmentStatus')}
              defaultValue={user?.financialInformation?.employmentStatus}
              options={[
                { label: 'Self Employed', value: 'Self Employed' },
                { label: 'Employed', value: 'Employed' },
                { label: 'Unemployed', value: 'Unemployed' },
              ]}
            />

            <InputField
              label="Residential Address"
              error={formState.errors['residentialAddress']}
              registration={register('residentialAddress')}
            />
            <InputField
              label="Permanent Address"
              error={formState.errors['permanentAddress']}
              registration={register('permanentAddress')}
            />
            <InputField
              label="Date of Birth"
              type="date"
              error={formState.errors['dateOfBirth']}
              registration={register('dateOfBirth')}
            />
            <SelectField
              label="Gender"
              registration={register('gender')}
              defaultValue={user?.basicInformation?.gender}
              options={[
                { label: 'Female', value: 'Female' },
                { label: 'Male', value: 'Male' },
              ]}
            />
            <InputField
              label="Tax Identification Number"
              error={formState.errors['tin']}
              registration={register('tin')}
            />
            <InputField
              label="Job Title"
              error={formState.errors['jobTitle']}
              registration={register('jobTitle')}
            />
            <InputField
              label="Salary"
              error={formState.errors['monthlyIncome']}
              registration={register('monthlyIncome')}
            />

            <InputField
              label="Employer Name"
              error={formState.errors['employerName']}
              registration={register('employerName')}
            />
            <PhoneInputField
              label="Employer Contact"
              error={formState.errors['employerContact']}
              registration={register('employerContact')}
            />
          </>
        )}
      </Form>
    </FormDrawer>
  );
};
