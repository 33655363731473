import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React from 'react';
import { Info } from 'react-feather';

export function Purpose({title,description}) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        <Info className="h-4 w-4" />
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            {title}
          </DialogTitle>
          <DialogDescription>
            {description}
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
