import React from 'react';
import Select from 'react-select';
import { FieldWrapper } from './FieldWrapper';


export const ReactMultiSelectField = props => {
  const {
    label,
    options,
    error,
    setValue,
    required,
    name,
    defaultValue,
    onMenuClose,
  } = props;

  const onMultiSelectStateChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : [];
    const selectedString = selectedValues.join(','); // Convert the array to a comma-separated string
    setValue(name, selectedString);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        isMulti={true} // Set the isMulti prop to enable multiple selections
        onMenuClose={onMenuClose}
        name={name}
        required={required}
        defaultValue={defaultValue}
        options={options}
        onChange={onMultiSelectStateChange}
        maxMenuHeight={100}
      />
    </FieldWrapper>
  );
};
