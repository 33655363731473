import { Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { useAuth } from 'lib/auth';
import React from 'react';

export function LenderMode() {
  const [show, setShow] = React.useState(false);

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleOpen}
        className={`text-${user.role === 'LENDER' && 'secondary'}-100`}
      >
        Lender Mode
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Lender Mode</DialogTitle>
          <DialogDescription>
            You are currently in lender mode where you can make offers and counter offers to
            borrowers. You can only become a borrower once you request for a loan.
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button
              onClick={handleClose}
              className="mt-4 p-2 px-4 bg-primary-100 w-full text-white rounded-2xl"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
