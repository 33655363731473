import Select from 'react-select';
import { FieldWrapper } from './FieldWrapper';
import { Purpose } from 'features/pigeonLoans/tips/Purpose';

export const ReactSelectFieldWithInfo = (props) => {
  const {
    label,
    options,
    error,
    name,
    onInputChange,
    required,
    defaultValue,
    onChange,
    onMenuClose,
    caption,
    isMulti = false,
    infoTitle,
    infoDescription,
  } = props;

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">{label}</div>
        <Purpose title={infoTitle} description={infoDescription} />
      </div>
      <div className="mb-1 text-xs text-gray-500 flex justify-start">{caption}</div>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        onMenuClose={onMenuClose}
        name={name}
        defaultValue={defaultValue}
        required={required}
        isMulti={isMulti}
        onInputChange={onInputChange}
        options={options}
        onChange={onChange}
        maxMenuHeight={100}
      />
      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-sm font-semibold text-red-500">
          {error.message}
        </div>
      )}
    </>
  );
};
