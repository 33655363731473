import { Navigate, Route, Routes } from 'react-router-dom';

import { Wishlist } from './Wishlist';

export const WishlistsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Wishlist />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
