import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import Modal from 'react-modal';
import 'leaflet/dist/leaflet.css';

Modal.setAppElement('#root');

const customIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const MapModal = ({ selectedPlace, setSelectedPlace, onClose }) => {
  const [locationName, setLocationName] = useState('Loading...');
  const [isLoading, setIsLoading] = useState(false);

  const fetchLocationName = async (lat, lng) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`,
        {
          headers: {
            'Accept-Language': 'en-US,en;q=0.9',
          },
        }
      );
      const data = await response.json();
      const address = data.address;
      const displayName = [
        address.road,
        address.suburb,
        address.city || address.town || address.village,
        address.state,
        address.country
      ]
        .filter(Boolean)
        .join(', ');

      setLocationName(displayName);
      setSelectedPlace({
        lat: lat,
        lng: lng,
        displayName: displayName,
        fullAddress: data.address
      });
    } catch (error) {
      console.error('Error fetching location name:', error);
      setLocationName('Location name not available');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPlace?.lat && selectedPlace?.lng) {
      fetchLocationName(selectedPlace.lat, selectedPlace.lng);
    }
  }, []); 

  const handleLocationUpdate = (lat, lng) => {
    fetchLocationName(lat, lng);
  };

  const handleMarkerDragEnd = (event) => {
    const { lat, lng } = event.target.getLatLng();
    handleLocationUpdate(lat, lng);
  };

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        handleLocationUpdate(lat, lng);
      },
    });
    return null;
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Map Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '800px',
          padding: '20px',
        },
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Select Location</h2>
          <button 
            onClick={onClose} 
            className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Close
          </button>
        </div>
        
        <div className="relative w-full h-[500px] rounded-lg overflow-hidden border">
          <MapContainer
            center={selectedPlace}
            zoom={13}
            style={{ width: '100%', height: '100%' }}
            scrollWheelZoom={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={selectedPlace}
              draggable={true}
              eventHandlers={{ dragend: handleMarkerDragEnd }}
              icon={customIcon}
            />
            <MapClickHandler />
          </MapContainer>
        </div>

        <div className="text-sm">
          <div className="font-semibold mb-1">Selected Location:</div>
          <div className="bg-gray-50 p-3 rounded">
            {isLoading ? (
              <div className="text-gray-500">Loading location name...</div>
            ) : (
              <div>{locationName}</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;