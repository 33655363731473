import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React from 'react';
import { Info } from 'react-feather';
import { formatCurrency } from 'utils/formatCurrency';

export function Borrow({ minAmount, maxAmount, bridge }) {
  const [show, setShow] = React.useState(false);

  const [componentBridge, setComponentBridge] = React.useState(bridge);

  const handleClose = () => {
    if (bridge) {
      setComponentBridge(false);
    }
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        <Info className="h-4 w-4" />
      </button>
      <Dialog isOpen={componentBridge || show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Limit Notice</DialogTitle>
          <DialogDescription>
            Please note that the product you've selected can allow you to only borrow minimum amount
            of UGX {minAmount?.toLocaleString()} and the maximum amount UGX{' '}
            {maxAmount?.toLocaleString()}
          </DialogDescription>
          <div className="flex flex-row items-center space-x-2">
            <button
              onClick={handleClose}
              className="mt-4 p-2 px-4 bg-primary-100 w-full text-white rounded-2xl"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
