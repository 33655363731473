import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getOffers = () => {
  return axios.get('/loans/my-loan/offers');
};

export const useOffers = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['myOffers'],
    queryFn: () => getOffers(),
  });
};
