import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const counterOffer = ({ data }) => {
  return axios.post(`/loans/counter-offer/${data.id}`, data.values);
};

export const useCounterOffer = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingLoan) => {
      await queryClient.cancelQueries(['loan', updatingLoan?.id]);

      const previousLoan = queryClient.getQueryData[('loan', updatingLoan?.id)];

      queryClient.setQueryData(['loan', updatingLoan?.id], {
        ...previousLoan,
        ...updatingLoan.data,
        id: updatingLoan.id,
      });

      return { previousLoan };
    },
    onError: (_, __, context) => {
      if (context?.previousLoan) {
        queryClient.setQueryData(['loan', context.previousLoan.id], context.previousLoan);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Offer submitted',
      });
    },
    ...config,
    mutationFn: counterOffer,
  });
};
