import React from 'react';

export const Stepper = ({ steps, currentStep, onStepClick }) => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex justify-between w-full mb-6 relative">
        <div className="absolute top-1/3 left-0 w-full h-0.5 bg-gray-300 transform -translate-y-1/2 z-0" />
        {steps.map((step, index) => (
          <div
            key={index}
            className={`relative flex flex-col items-center cursor-pointer z-10 ${
              index <= currentStep ? 'text-secondary-100' : 'text-gray-500'
            }`}
            onClick={() => onStepClick(index)}
          >
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full transition-colors duration-300 ${
                index === currentStep ? 'bg-secondary-100 text-white' : 'bg-gray-300'
              }`}
            >
              {index + 1}
            </div>
            <div className="mt-2 text-xs text-center">{step.title}</div>
          </div>
        ))}
      </div>
      <div className="w-full p-4 border border-gray-300 rounded-lg bg-white shadow-md">
        {steps[currentStep].content}
      </div>
    </div>
  );
};
